import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: {},
  inactive: {},
  trashed: {},
};

const ServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, { payload }) => {
      state.services = payload.services || {};
      state.inactive = payload.inactive || {};
    },
    moveServiceToActive: (state, { payload }) => {
      const { id } = payload;
      const service = state.inactive["service_" + id];
      if (service) {
        delete state.inactive["service_" + id];
        state.services["service_" + id] = service;
      }
    },
    setService: (state, { payload }) => {
      if (
        !state[payload.active ? "services" : "inactive"][
          "service_" + payload.id
        ]
      ) {
        state[payload.active ? "services" : "inactive"] = {
          ["service_" + payload.id]: payload,
          ...state[payload.active ? "services" : "inactive"],
        };
      } else {
        state[payload.active ? "services" : "inactive"][
          "service_" + payload.id
        ] = payload;
      }
    },
    removeService: (state, { payload }) => {
      delete state[payload.active ? "services" : "inactive"][
        "service_" + payload.id
      ];
    },
    setTrashedService: (state, { payload }) => {
      console.log("payload",payload)
      state.trashed = payload.services || {};
    },
  },
});

export const { setServices, setService, removeService, setTrashedService , moveServiceToActive } =
  ServiceSlice.actions;
export default ServiceSlice.reducer;

export const getServicesCount = (state) => {
  return (
    Object.keys(state.service.services ?? {}).length +
    Object.keys(state.service.inactive ?? {}).length
  );
};

export const getFeaturedServices = createDraftSafeSelector(
  (state) => state.service,
  (service) => {
    let allServices = { ...service.services, ...service.inactive };
    allServices = Object.values(allServices);
    return allServices?.filter((service) => service.isFeatured);
  }
);
