import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: {},
};

const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    setCustomer: (state, { payload }) => {
      if (!state.customers["customer_" + payload.id]) {
        state.customers = {
          ["customer_" + payload.id]: payload,
          ...state.customers,
        };
      } else {
        state.customers["customer_" + payload.id] = payload;
      }
    },
    removeCustomer: (state, { payload }) => {
      delete state.customers["customer_" + payload.id];
    },
  },
});

export const { setCustomers, setCustomer, removeCustomer } =
  CustomerSlice.actions;
export default CustomerSlice.reducer;

export const getCustomersCount = (state) => {
  return Object.keys(state.customer.customers ?? {}).length;
};
