import Delete from "assets/images/icons/delete.svg";
import edit from "assets/images/icons/edit.svg";
import filter1 from "assets/images/icons/filter1.svg";
import sort from "assets/images/icons/sort.svg";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { Table } from "antd";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
// import providerOfferslist from "assets/json/providerOffers";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "services/axios";
import {
  asyncDeleteService,
  asyncGetAllServices,
  asyncGetAllTrashedServices,
  asyncMarkServiceFeatured,
  asyncUpdateTrashServiceStatus,
} from "store/service/serviceThunk";
import { formatDate, serviceTableTabs, getColumnSearchProps } from "utils";
import styles from "./adminService.module.css";
import classNames from "classnames";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";
// import { getServicesByRoute } from "store/service/serviceSlice";
// import { useLoaderDispatch } from "hooks/useLoaderDispatch";
// import AskModal from "components/AskModal";

const AdminServices = () => {
  // const data = providerOfferslist.providerOfferslist;
  const [serviceId, setServiceId] = useState(null);
  const [serviceCondition, setServiceCondition] = useState(null);

  const [servicesLoader, fetchAllServices] =
    useLoaderDispatch(asyncGetAllServices);

  const dispatch = useDispatch();
  const location = useLocation();

  let route = location.pathname
    .split("/")
    .filter((x) => x)
    .pop();

  // const services = useSelector((state) => getServicesByRoute(state, route));
  // const loading = useSelector((state) => state.common.loading);

  let services = useSelector((state) => {
    if (route) {
      return state.service?.[route];
    }
    return [];
  });

  services = useMemo(() => Object.values(services ?? {}), [services]);

  // const loadData = useCallback(async () => {
  //   dispatch(asyncGetAllServices());
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const handleDeleteService = async () => {
    let res = await dispatch(asyncDeleteService(serviceId)).unwrap();
    if (res.success) {
      setServiceId(null);
    }
  };
  const handleUpdateService = async () => {
    if (serviceCondition) {
      let res = await dispatch(
        asyncUpdateTrashServiceStatus({
          id: serviceId,
          condition: !serviceCondition,
        })
      ).unwrap();
      if (res.success) {
        setServiceId(null);
        setServiceCondition(null);
      }
    } else {
      let res = await dispatch(asyncDeleteService(serviceId)).unwrap();
      if (res.success) {
        setServiceId(null);
      }
    }
  };

  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Service",
      dataIndex: "name",
      render: (name, record) => (
        <div className="table-imgname">
          <img src={BASE_URL_IMAGE + record.image} className="me-2" alt="img" />
          <span>{name}</span>
        </div>
      ),
      ...getColumnSearchProps(searchInput, "name"),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active ,record) => (
        <h6 className={!record.serviceTrashed ? "badge-active" : "badge-inactive"}>
          {!record.serviceTrashed ? "Active" : "In Active"}
        </h6>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="table-actions d-flex">
          <Link className="delete-table me-2" to={`${record?.id}`}>
            <img src={edit} alt="svg" />
          </Link>
          <Link
            className="delete-table me-2"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-item"
            onClick={(e) => {
              e.preventDefault();
              setServiceId(record?.id);
              setServiceCondition(record.serviceTrashed);
            }}
          >
          {
            record?.serviceTrashed ?
            <i className="fe fe-list">
                <FeatherIcon icon={"play"} />
              </i>
            :
            <i className="fe fe-list">
                <FeatherIcon icon={"pause"} />
              </i>

          }
          </Link>
          <div className="d-flex align-items-center">
            <Link
              className={`delete-table me-2 ${
                record?.isFeatured ? "bg-primary text-white" : ""
              }`}
              to="#"
              onClick={(e) => {
                dispatch(
                  asyncMarkServiceFeatured({
                    serviceId: record?.id,
                    isFeatured: !record?.isFeatured,
                  })
                );
              }}
            >
              <i className="fe fe-list">
                <FeatherIcon icon={"check-circle"} />
              </i>
            </Link>
            {record?.isFeatured ? (
              <span className="text-primary text-white">Featured</span>
            ) : (
              <span>Mark As Featured</span>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>All Services</h5>
            <div className="list-btn">
              <ul>
                {/* <li>
                  <Link className="btn-filters active" to="/services">
                    <i className="fe fe-list">
                      <FeatherIcon icon={"list"} />
                    </i>{" "}
                  </Link>
                </li>
                <li>
                  <Link className="btn-filters" to="/localization">
                    <i className="fe fe-settings">
                      <FeatherIcon icon={"settings"} />
                    </i>{" "}
                  </Link>
                </li>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <img src={filter1} className="me-2" alt="img" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <img src={sort} className="me-2" alt="img" />
                        </span>
                        <div className="review-sort">
                          <Select options={options} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li> */}
                {route === "services" && (
                  <li>
                    <Link className="btn btn-primary" to="add">
                      <i className="fa fa-plus me-2" />
                      Add Services
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={classNames("tab-sets", styles.serviceTabs)}>
                <div
                  className={classNames("tab-contents-sets", styles.tabContent)}
                >
                  <ul className={styles.tabs}>
                    {serviceTableTabs.map((tab) => (
                      <li key={tab.key}>
                        <Link
                          to={tab.path}
                          className={classNames(
                            location.pathname === tab.path ? "active" : "",
                            styles.tabLink
                          )}
                        >
                          {tab.value}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="tab-contents-count">
                  <h6>Showing 8-10 of 84 results</h6>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0 p-md-auto ">
              <div className="table-resposnive table-div pt-2 pt-md-3">
                <div className="table datatable">
                  <Table
                    scroll={{
                      x: 670,
                    }}
                    columns={columns}
                    dataSource={services}
                    loading={servicesLoader}
                    rowKey={(record) => record.id}
                    showSizeChanger={true}
                    pagination={{
                      total: services?.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AskModal
        id="delete-item"
        status="Delete"
        title={"Service"}
        onClick={handleDeleteService}
        onCancel={() => setServiceId(null)}
      /> */}
      {/* Delete */}
      <div
        className="modal fade"
        id="delete-item"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {!serviceCondition ? "Inactivate Services" : "Activate Service"}
              </h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fe fe-x">
                  <FeatherIcon icon={"x"} />
                </i>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="del-modal">
                <p>
                  {!serviceCondition
                    ? "Are you sure you want to deactivate service?"
                    : "Are you sure you want to activate service?"}
                </p>
              </div>
            </div>
            <div className="modal-footer pt-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setServiceId(null)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleUpdateService}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete */}
    </>
  );
};
export default AdminServices;
