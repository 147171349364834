import { Form } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import {
  asyncForgotPassword,
  asyncResetPassword,
  asyncVerifyToken,
} from "store/auth/authThunk";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const resetLink = searchParams.get("token");
  const [showConfirmPass, setConfirmShowPass] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const verifyToken = useCallback(async () => {
    let res = await dispatch(asyncVerifyToken({ resetLink })).unwrap();
    console.log(res, "check");
    if (!res.success) {
      return navigate("/login");
    }
    setIsTokenValid(res.success);
  }, [resetLink]);

  useEffect(() => {
    if (!resetLink) return navigate("/");
    verifyToken();
  }, [resetLink]);
  const onFinish = useCallback(async (values) => {
    const body = { ...values, resetLink };
    const { success } = await dispatch(asyncResetPassword(body)).unwrap();
    if (success) {
      form.resetFields();
      navigate("/login");
    }
  }, []);

  if (!isTokenValid)
    return (
      <div
        style={{
          width: "100vw",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        Validating Token, Please Wait...
      </div>
    );
  return (
    <div className="content mt-5 p-1">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h4>Reset Password</h4>
              </div>
              <Form
                form={form}
                name="login"
                initialValues={{
                  email: "",
                  password: "",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="col-form-label d-block">Password</label>
                  <div className="pass-group">
                    <Form.Item
                      name="newPassword"
                      validateFirst
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input your password!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                      ]}
                    >
                      <span>
                        <input
                          type={showPass ? "text" : "password"}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showPass ? "eye-off" : "eye"}
                            onClick={() => setShowPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label d-block">
                    Confirm Password
                  </label>
                  <div className="pass-group">
                    <Form.Item
                      name="confirmPassword"
                      validateFirst
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input your Confirm password!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                      ]}
                    >
                      <span>
                        <input
                          type={showConfirmPass ? "text" : "password"}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showConfirmPass ? "eye-off" : "eye"}
                            onClick={() => setConfirmShowPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <button
                  className="btn btn-primary w-100 "
                  type="button"
                  onClick={() => form.submit()}
                >
                  submit
                </button>
              </Form>
              {/* <p className="no-acc">
                {`back to login ? `}
                <Link to="/login">Login</Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
