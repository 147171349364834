import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  error: null,
  success: null,
  notification: null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { setError, setSuccess, setNotification, setLoading } =
  commonSlice.actions;
export default commonSlice.reducer;
