// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor_serviceTabs__eEdCI .vendor_tabContent__I117C .vendor_tabs__mHl2r {
  width: 100%;
  flex-wrap: unset;
  column-gap: 1rem;
}
.vendor_serviceTabs__eEdCI .vendor_tabContent__I117C .vendor_tabs__mHl2r li {
  padding-left: 0;
}
.vendor_serviceTabs__eEdCI .vendor_tabLink__mMAry {
  width: max-content;
}

@media screen and (max-width: 576px) {
  .vendor_serviceTabs__eEdCI .vendor_tabLink__mMAry {
    padding: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Users/vendor.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".serviceTabs .tabContent .tabs {\n  width: 100%;\n  flex-wrap: unset;\n  column-gap: 1rem;\n}\n.serviceTabs .tabContent .tabs li {\n  padding-left: 0;\n}\n.serviceTabs .tabLink {\n  width: max-content;\n}\n\n@media screen and (max-width: 576px) {\n  .serviceTabs .tabLink {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceTabs": `vendor_serviceTabs__eEdCI`,
	"tabContent": `vendor_tabContent__I117C`,
	"tabs": `vendor_tabs__mHl2r`,
	"tabLink": `vendor_tabLink__mMAry`
};
export default ___CSS_LOADER_EXPORT___;
