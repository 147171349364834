import React, { useCallback, useMemo } from "react";
import { Button, Drawer, Form, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { setSelected } from "store/booking/bookingSlice";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import SelectBox from "commoncomponent/Select/Select";
import {
  bookingStatuses,
  formatDate,
  statusBadges,
  serviceStatuses,
  toNumber,
  validateDecimalsPrecision,
} from "utils";
import {
  asyncCreateQuotation,
  asyncDiscardServiceBooking,
  asyncResolveServiceBookingDispute,
  asyncUpdateBooking,
  asyncUpdateQuotation,
} from "store/booking/bookingThunk";
// import QuotesDrawer from "./QuotesDrawer";
import QuotesDrawer from "./QuotesModal";
import { formatPhoneNumber } from "react-phone-number-input";
import DiscardModal from "commoncomponent/ConfirmModal";
import DisputeModal from "commoncomponent/ConfirmModal";

const AppDrawer = ({ open, booking, resetBookingId }) => {
  const [form] = Form.useForm();
  const [formTotal] = Form.useForm();
  const [selectedBookingService, setBookingService] = React.useState(null);
  const [bookingServiceId, setBookingServiceId] = React.useState(null);
  const [quoteModal, setQuoteModal] = React.useState(false);

  const dispatch = useDispatch();

  let vendors = useSelector((state) => state.vendor.vendors);
  let customers = useSelector((state) => state.customer.customers);

  const specificVendors = useMemo(
    () =>
      Object.values(vendors ?? {}).filter((vendor) =>
        vendor?.vendorServices.some(
          (vendorService) =>
            vendorService?.service?.id ===
            selectedBookingService?.service?.parentId
        )
      ),
    [selectedBookingService?.service?.parentId]
  );

  const handleCancel = useCallback(() => {
    resetBookingId();
    setBookingService(null);
  }, []);

  const handleServiceCancel = useCallback(() => {
    setBookingService(null);
  }, []);

  const handleQuoteCancel = useCallback(() => {
    setQuoteModal(null);
  }, []);

  const handleQuoteSave = useCallback(async (values) => {
    let res = await dispatch(asyncUpdateQuotation(values.quoteId)).unwrap();
    if (res.success) {
      handleQuoteCancel();
    }
  }, []);

  const onFinish = useCallback(
    async ({ vendorId }) => {
      vendorId = vendorId?.map((vendor) => {
        return { id: vendor.value };
      });

      await dispatch(
        asyncCreateQuotation({
          bookingId: selectedBookingService?.id,
          vendorId,
        })
      ).unwrap();
      setBookingService(null);
    },
    [selectedBookingService?.id]
  );

  const onFinishCommission = useCallback(
    async (values) => {
      await dispatch(
        asyncUpdateBooking({ bookingId: booking?.id, ...values })
      ).unwrap();
    },
    [booking?.id]
  );

  // Booking Services has to be in 2 state ( discarded, vendor Assigned )
  let isBookingReadyToProceed = useMemo(() => {
    let assignedServiceCount = 0;
    let discardedServiceCount = 0;

    booking?.bookingServices?.forEach((bookingService) => {
      if (bookingService?.discarded) {
        ++discardedServiceCount;
      } else if (bookingService?.quoteId) {
        ++assignedServiceCount;
      }
    });

    let isAllProcessed =
      assignedServiceCount + discardedServiceCount ===
      booking?.bookingServices?.length;

    if (assignedServiceCount > 0 && isAllProcessed) {
      return true;
    } else if (!isAllProcessed) {
      return false;
    } else {
      return null;
    }
  }, [booking.id, booking.bookingServices]);

  let allQuotesAmount = useMemo(
    () =>
      booking?.bookingServices?.reduce((acc, curr) => {
        if (curr.discarded) return acc;
        return acc + toNumber(curr?.assignedQuote?.quoteAmount);
      }, 0),
    [booking.id, booking.bookingServices]
  );

  let customer = customers["customer_" + booking?.user?.id] || booking?.user;

  let totalAmount =
    toNumber(booking?.totalAmount) + toNumber(booking?.adminCharges);
  let taxAmount = Number((totalAmount * 0.1).toFixed(2));
  let totalWTax = (totalAmount + taxAmount).toFixed(2);

  const isAllServicesDiscarded = booking?.bookingServices?.every(
    (bookingService) => {
      return bookingService?.discarded;
    }
  );
  console.log(
    "selectedBookingService",
    selectedBookingService?.quotes?.map((vendor) => ({
      label: vendor?.vendor?.name,
      value: vendor.id,
    }))
  );
  return (
    <Drawer
      placement="bottom"
      closeIcon={""}
      zIndex={9999}
      size={"large"}
      height={"90vh"}
      width={"60vw"}
      open={open}
      getContainer={false}
      extra={
        <Space>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            Close
          </button>
        </Space>
      }
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-xl font-600 mt-3 mb-3">Booking# {booking?.id}</h2>
        <h6
          className={
            statusBadges[isAllServicesDiscarded ? 18 || 19 : booking?.status]
          }
        >
          {isAllServicesDiscarded
            ? bookingStatuses[19]?.value
            : +booking?.status === 6 && !booking?.isCompleted
            ? bookingStatuses[5]?.value
            : bookingStatuses[booking?.status]?.value}
        </h6>
      </div>
      <div
        className="row p-4 mx-0 rounded-2"
        style={{
          backgroundColor: "#E5E9F2",
        }}
      >
        <div className="col-xl-2">
          <p className="text-xl font-600 mb-0">Customer Name</p>
          <p className="fw-bold">{customer?.name}</p>
        </div>
        <div className="col-xl-2">
          <p className="text-xl font-600 mb-0">Phone Number</p>
          <p className="fw-bold">
            {formatPhoneNumber(customer?.mobileNumber) || "N/A"}
          </p>
        </div>
        <div className="col-xl-2">
          <p className="text-xl font-600 mb-0">Booking Date</p>
          <p className="fw-bold">{formatDate(booking?.bookingDate)}</p>
        </div>
        <div className="col-xl-2">
          <p className="text-xl font-600 mb-0">Booking Time</p>
          <p className="fw-bold">{booking?.bookingTime}</p>
        </div>
        <div className="col-xl-3">
          <p className="text-xl font-600 mb-0">Email</p>
          <p className="fw-bold">{customer?.email}</p>
        </div>
        <div className="col-xl-4">
          <p className="text-xl font-600 mb-0">Description</p>
          <p className="fw-bold mb-0">{booking?.description ?? "N/A"}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Form form={form} name="vendorList" onFinish={onFinish}>
            <h2 className="text-xl font-600 mt-3 mb-3">Services</h2>
            {booking?.bookingServices &&
              booking?.bookingServices.map((bookingService, index) => {
                // GROUP PARAMS BASED ON HEADING AND SINGLE ITEM
                const bookingParams =
                  bookingService?.serviceBookingParams.reduce(
                    (acc, curr, index) => {
                      let groupHeading =
                        curr?.serviceParameter?.groupHeading ?? null;
                      let key = groupHeading ?? index;
                      let value = null;

                      try {
                        value = JSON.parse(curr?.value);
                      } catch (e) {
                        value = null;
                      }

                      let objPair = {
                        label: curr?.serviceParameter?.label,
                        type: curr?.serviceParameter?.type,
                        value,
                      };

                      return {
                        ...acc,
                        [key]: {
                          groupHeading,
                          ...(groupHeading
                            ? {
                                rows:
                                  acc[groupHeading]?.rows.length > 0
                                    ? [...acc[groupHeading].rows, objPair]
                                    : [objPair],
                              }
                            : objPair),
                        },
                      };
                    },
                    {}
                  );

                let hasQuotes = bookingService?.quotes?.length > 0;
                let isQuotesHasAmount = bookingService?.quotes?.some(
                  (quote) => toNumber(quote?.quoteAmount) > 0
                );

                let status = bookingService?.disputeReason
                  ? 18
                  : bookingService?.discarded
                  ? 19
                  : +bookingService?.status;

                let assignedQuote = bookingService?.assignedQuote;
                let selectedVendor = assignedQuote?.vendor;
                return (
                  <div
                    key={bookingService?.id}
                    className={`${
                      index % 2 === 0
                        ? " border rounded-top"
                        : " border-top-0 rounded-bottom"
                    } border border-gray-300 p-4`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h4
                        style={{
                          color: "#fe5000",
                        }}
                      >
                        {bookingService?.service?.name
                          ?.split(" ")
                          ?.map(
                            (word) =>
                              word?.charAt(0)?.toUpperCase() + word?.slice(1)
                          )
                          ?.join(" ")}
                      </h4>
                      <h6 className={statusBadges[status]}>
                        {serviceStatuses[status]}
                      </h6>
                    </div>
                    <p className="fw-bold mb-0">
                      {bookingService?.to && bookingService?.from ? (
                        <>
                          <span className="me-5">To: {bookingService.to}</span>
                          <span>From: {bookingService.from}</span>
                        </>
                      ) : (
                        <span>Location: {booking?.address}</span>
                      )}
                    </p>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="row">
                        {Object?.values(bookingParams ?? [])?.map((param) => {
                          let data = param?.rows ?? [param];
                          return (
                            <div key={param.id} className="pt-2">
                              <div className="border border-gray-300 px-3 py-2 rounded-2">
                                {param.groupHeading && (
                                  <p className="fw-bold fs-5 mb-2">
                                    {param.groupHeading}
                                  </p>
                                )}
                                <div className="d-flex gap-5">
                                  {data?.map((row) => {
                                    let value = row?.value;

                                    if (typeof value === "object") {
                                      if (!Array.isArray(value)) {
                                        value = [value];
                                      }

                                      value = value.reduce(
                                        (acc, { label, value }) => {
                                          const tempValue =
                                            row?.type === "params"
                                              ? `${label}(${value})`
                                              : value;
                                          return acc === ""
                                            ? tempValue
                                            : `${acc} | ${tempValue}`;
                                        },
                                        ""
                                      );
                                    }

                                    return (
                                      <div key={row?.id} className="d-flex">
                                        <div>
                                          <p className="mb-0">{row?.label}</p>
                                          <p className="fw-bold mb-0">
                                            <span>{value}</span>
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {!bookingService?.quoteId &&
                          !bookingService?.discarded && (
                            <button
                              type="button"
                              role="button"
                              className="btn btn-primary d-flex cursor-pointer align-items-center gap-2"
                              onClick={() => {
                                setBookingService(bookingService);
                                if (isQuotesHasAmount) {
                                  setQuoteModal(true);
                                } else {
                                  // form.resetFields(["vendorId"]);
                                  console.log("bookingService", bookingService);
                                }
                                if (!hasQuotes) {
                                  form.resetFields(["vendorId"]);
                                }
                              }}
                            >
                              {hasQuotes
                                ? isQuotesHasAmount
                                  ? "View Vendors Quotation"
                                  : "No Vendor interacted Yet"
                                : "Forward To Vendor"}
                              <FeatherIcon icon={"arrow-right"} />
                            </button>
                          )}
                        {/* <button
                          type="button"
                          role="button"
                          className="btn btn-primary d-flex cursor-pointer align-items-center gap-2"
                          onClick={() => {
                            setBookingService(bookingService);

                            form.resetFields(["vendorId"]);
                          }}
                        >
                          Forward To Vendor
                          <FeatherIcon icon={"arrow-right"} />
                        </button> */}
                        {(status === 1 || status === 18) &&
                          (!bookingService.discarded ||
                            bookingService.disputeReason) && (
                            <button
                              type="button"
                              role="button"
                              data-bs-toggle="modal"
                              data-bs-target={
                                status === 1 ? "#discard-item" : "#dispute-item"
                              }
                              className={`btn ${
                                status === 18 ? "btn-success" : "btn-warning"
                              } text-white d-flex cursor-pointer align-items-center ms-auto mt-2 gap-2`}
                              onClick={(event) => {
                                event.preventDefault();
                                setBookingServiceId(bookingService?.id);
                              }}
                            >
                              {status === 1
                                ? "Dicard Service"
                                : "Resolve Dispute"}
                            </button>
                          )}
                        {bookingService?.discarded && (
                          <h5 className="btn btn-danger">Discarded By Admin</h5>
                        )}
                      </div>
                    </div>

                    {selectedBookingService?.id === bookingService?.id && (
                      <div className="d-flex justify-content-between mt-3">
                        <div className="w-75">
                          <Form.Item
                            name="vendorId"
                            rules={[
                              {
                                required: true,
                                message: "Vendors are required!",
                              },
                            ]}
                          >
                            <SelectBox
                              isMulti={true}
                              defaultValue={selectedBookingService?.quotes?.map(
                                (vendor) => ({
                                  label: vendor?.vendor?.name,
                                  value: vendor.id,
                                })
                              )}
                              options={specificVendors?.map((vendor) => ({
                                label: vendor.name,
                                value: vendor.id,
                              }))}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <button
                            className="btn btn-danger me-2"
                            onClick={handleServiceCancel}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary border border-1 border-primary"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    )}

                    {bookingService?.quoteId && (
                      <div
                        className="border text-white border-gray-300 p-3 rounded-2 mt-3"
                        style={{
                          background: "#fe5000",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <h4 className="text-white">Vendor</h4>
                          <h4 className="text-white">
                            ${assignedQuote?.quoteAmount}
                          </h4>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex gap-5">
                            <div>
                              <p className="mb-0">Name</p>
                              <p className="fw-bold mb-0">
                                {vendors["vendor_" + assignedQuote?.vendorId]
                                  ?.name || selectedVendor?.name}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">Email</p>
                              <p className="fw-bold mb-0">
                                {vendors["vendor_" + assignedQuote?.vendorId]
                                  ?.email || selectedVendor?.email}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">Service Charges</p>
                              <p className="fw-bold mb-0">
                                ${assignedQuote?.quoteAmount}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">Description</p>
                              <p className="fw-bold mb-0">
                                {assignedQuote?.quoteDescription}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {!bookingService?.quoteId &&
                      !!bookingService?.quotes?.length && (
                        <div
                          className="border text-white border-gray-300 p-3 rounded-2 mt-3"
                          style={{
                            background: "#fe5000",
                          }}
                        >
                          <h4 className="text-white">
                            Quote Request Sended To These Vendors
                          </h4>
                          <ol>
                            {bookingService?.quotes?.map((quote) => {
                              return (
                                <li key={quote?.id}>
                                  <div className="d-flex gap-5">
                                    <div>
                                      <p className="mb-0">Name</p>
                                      <p className="fw-bold mb-0">
                                        {vendors["vendor_" + quote?.vendorId]
                                          ?.name ||
                                          quote?.vendor?.name ||
                                          "N/A"}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0">Email</p>
                                      <p className="fw-bold mb-0">
                                        {vendors["vendor_" + quote?.vendorId]
                                          ?.email ||
                                          quote?.vendor?.email ||
                                          "N/A"}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0">Status</p>
                                      <p className="fw-bold mb-0">
                                        {Number(quote?.quoteAmount) > 0
                                          ? "Provided"
                                          : "Waiting"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      )}
                  </div>
                );
              })}
          </Form>
        </div>
        <div className="col-12">
          <Form
            form={formTotal}
            name="totalAmountWCommission"
            onFinish={onFinishCommission}
          >
            <div className="border rounded-top-0 rounded-bottom p-4">
              <div className="d-flex justify-content-between px-3">
                <h3>Sub Total</h3>
                <h3>${allQuotesAmount.toFixed(2)}</h3>
              </div>
              <div className="d-flex justify-content-end px-3">
                {isBookingReadyToProceed &&
                  (+booking?.status === 1 ? (
                    <Form.Item
                      name="amount"
                      validateFirst
                      rules={[
                        {
                          required: true,
                          message: "Please input Amount!",
                        },
                        validateDecimalsPrecision,
                      ]}
                    >
                      <div className="input-group border rounded">
                        <div className="btn btn-white border-0">
                          Company Charges
                        </div>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control bg-white border-0"
                          placeholder="$00.00"
                        />
                      </div>
                    </Form.Item>
                  ) : (
                    <div className="text-end">
                      <h3>Company Charges: ${booking?.adminCharges}</h3>
                      <h3>Tax(GST): ${taxAmount}</h3>
                    </div>
                  ))}
              </div>
              <div
                className="d-flex justify-content-between align-items-center p-3 rounded"
                style={{
                  background: "#fe5000",
                }}
              >
                <h4 className="mb-0 text-white">Total Amount</h4>
                <h4 className="mb-0 text-white">${totalWTax}</h4>
              </div>
              {+booking?.status === 1 && (
                <button
                  type="button"
                  onClick={() => {
                    isBookingReadyToProceed ? formTotal.submit() : null;
                  }}
                  className="btn btn-success mt-4 ms-auto d-block"
                >
                  {isBookingReadyToProceed === null
                    ? "All Services Are Discarded, Can't Proceed"
                    : isBookingReadyToProceed
                    ? "Send To Customer"
                    : "Services Yet Waiting For Vendors"}
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>

      {selectedBookingService?.id && (
        <QuotesDrawer
          open={quoteModal}
          handleCancel={handleQuoteCancel}
          handleSave={handleQuoteSave}
          bookingService={selectedBookingService}
        />
      )}
      <DiscardModal
        id={"discard-item"}
        title={"Discard Booking Service"}
        msg={"Are you sure you want to discard?"}
        onDone={() => dispatch(asyncDiscardServiceBooking(bookingServiceId))}
        onCancel={() => setBookingServiceId(null)}
      />
      <DisputeModal
        id={"dispute-item"}
        title={"Resolve Booking Service Dispute"}
        msg={"Are you sure you want to resolve dispute?"}
        onDone={() =>
          dispatch(asyncResolveServiceBookingDispute(bookingServiceId))
        }
        onCancel={() => setBookingServiceId(null)}
      />
    </Drawer>
  );
};

export default AppDrawer;
