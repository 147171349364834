// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_control__eVDIw {
  border: 1px solid #f3f3f3 !important;
  box-shadow: none;
  color: #2a313d;
  height: 46px;
  padding-inline: 8px;
  border-radius: 8px !important;
  transition: all 0.5s;
  font-size: 14px;
  background: #f9f9fb !important;
}
.style_placeholder__YQRjw {
  color: #E4E4E9;
}
`, "",{"version":3,"sources":["webpack://./src/commoncomponent/Select/style.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;EAC7B,oBAAoB;EACpB,eAAe;EACf,8BAA8B;AAChC;AACA;EACE,cAAc;AAChB","sourcesContent":[".control {\n  border: 1px solid #f3f3f3 !important;\n  box-shadow: none;\n  color: #2a313d;\n  height: 46px;\n  padding-inline: 8px;\n  border-radius: 8px !important;\n  transition: all 0.5s;\n  font-size: 14px;\n  background: #f9f9fb !important;\n}\n.placeholder {\n  color: #E4E4E9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `style_control__eVDIw`,
	"placeholder": `style_placeholder__YQRjw`
};
export default ___CSS_LOADER_EXPORT___;
