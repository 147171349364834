import {
  createDraftSafeSelector,
  createSlice,
  original,
} from "@reduxjs/toolkit";
import { bookingStatuses } from "utils";

const initialState = {
  bookings: {},
  pending: {},
  decision: {},
  accepted: {},
  declined: {},
  inprogress: {},
  completed: {},
  selected: {},
};

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setSelectedBooking: (state, { payload }) => {
      state.selected = payload;
    },
    setBookings: (state, { payload }) => {
      state.bookings = payload.bookings || {};
      state.pending = payload.pending || {};
      state.decision = payload.decision || {};
      state.accepted = payload.accepted || {};
      state.declined = payload.declined || {};
      state.inprogress = payload.inprogress || {};
      state.completed = payload.completed || {};
    },
    setBooking: (state, { payload }) => {
      let bookings = original(state.bookings);
      let prevBooking = bookings?.["booking_" + payload.id] ?? {};
      let prevStatus = prevBooking.status;

      if (!state.bookings["booking_" + payload.id]) {
        state.bookings = {
          ["booking_" + payload.id]: payload,
          ...state.bookings,
        };
      } else {
        state.bookings["booking_" + payload.id] = payload;
      }

      if (
        !state[bookingStatuses[payload?.status].key]["booking_" + payload.id]
      ) {
        state[bookingStatuses[payload?.status].key] = {
          ["booking_" + payload.id]: payload,
          ...state[bookingStatuses[payload?.status].key],
        };
      } else {
        state[bookingStatuses[payload?.status].key]["booking_" + payload.id] =
          payload;
      }

      if (state.selected.id === payload.id) {
        state.selected = state.bookings["booking_" + payload.id];
      }

      if (payload.status !== prevStatus && !!prevStatus) {
        delete state[bookingStatuses[prevStatus].key]["booking_" + payload.id];
      }
    },
    setQuotes: (state, { payload }) => {
      let {
        bookingId,
        serviceBookingId,
        bookingStatus = null,
        ...data
      } = payload;

      let booking = original(state.bookings["booking_" + bookingId]);
      let bookingServices = original(
        state.bookings["booking_" + bookingId]["bookingServices"]
      );

      let updatedBookingServices = bookingServices?.map((service) => {
        if (service.id === serviceBookingId) {
          return { ...service, ...data };
        }
        return service;
      });

      state.bookings["booking_" + bookingId]["bookingServices"] =
        updatedBookingServices;
      state[bookingStatuses[booking.status].key]["booking_" + bookingId][
        "bookingServices"
      ] = updatedBookingServices;

      if (state.selected.id === bookingId) {
        state.selected = state.bookings["booking_" + bookingId];
      }

      if (booking.id && booking.status !== bookingStatus && !!bookingStatus) {
        state.bookings["booking_" + bookingId];
        BookingSlice.caseReducers.setBooking(state, {
          payload: {
            ...booking,
            status: bookingStatus,
            bookingServices: updatedBookingServices,
          },
        });
      }
    },
    setServiceBookingProperties: (state, { payload }) => {
      let { bookingId, id, param } = payload;

      let bookingServices = original(
        state.bookings["booking_" + bookingId]["bookingServices"]
      );

      let updatedBookingServices = bookingServices?.map((service) => {
        if (service.id === id) {
          return { ...service, ...param };
        }
        return service;
      });

      state.bookings["booking_" + bookingId]["bookingServices"] =
        updatedBookingServices;

      if (state.selected.id === bookingId) {
        state.selected = state.bookings["booking_" + bookingId];
      }
    },
  },
});

export const {
  setSelectedBooking,
  setBookings,
  setBooking,
  setQuotes,
  setServiceBookingProperties,
} = BookingSlice.actions;
export default BookingSlice.reducer;

export const getRecentBookings = createDraftSafeSelector(
  (state) => state.booking.pending,
  (pending) => Object.values(pending ?? {}).slice(0, 10)
);
