import { Table } from "antd";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { asyncGetAllBookings } from "store/booking/bookingThunk";
import {
  bookingStatuses,
  bookingTableTabs,
  formatDate,
  getColumnSearchProps,
  serviceStatuses,
  statusBadges,
} from "utils";

// import Delete from "assets/images/icons/delete.svg";
// import edit from "assets/images/icons/edit.svg";
import eye from "assets/images/icons/eye.svg";
// import AdminHeader from "commoncomponent/AdminHeader";
import classNames from "classnames";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";
import { setSelectedBooking } from "store/booking/bookingSlice";
import { asyncGetAllVendors } from "store/vendor/vendorThunk";
import AppDrawer from "./AppDrawer";
import styles from "./booking.module.css";

const AdminBooking = () => {
  // const [bookingId, setBookingId] = useState(null);

  const [bookingsLoader, fetchAllBookings] =
    useLoaderDispatch(asyncGetAllBookings);
  const [_, fetchAllVendors] = useLoaderDispatch(asyncGetAllVendors);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  let route = location.pathname
    .split("/")
    .filter((x) => x)
    .pop();

  let bookings = useSelector((state) => {
    if (route) {
      return state.booking?.[params?.id ? "bookings" : route];
    }
    return [];
  });

  const bookingByRouteId = bookings?.["booking_" + params?.id];
  const selectedBooking = useSelector((state) => state.booking.selected);

  // let selectedBooking = bookings?.["booking_" + bookingId];
  bookings = useMemo(() => Object.values(bookings ?? {}), [bookings]);

  // const loading = useSelector((state) => state.common.loading);

  const dispatch = useDispatch();

  // const loadData = useCallback(async () => {
  //   dispatch(asyncGetAllVendors()).unwrap();
  //   await dispatch(asyncGetAllBookings()).unwrap();
  // }, [dispatch]);

  useEffect(() => {
    fetchAllVendors();
    fetchAllBookings();

    return () => {
      if (!params?.id && !bookingByRouteId?.id) {
        dispatch(setSelectedBooking({}));
      }
    };
  }, [fetchAllVendors, fetchAllBookings, route]);

  useEffect(() => {
    if (params?.id && bookings?.length) {
      if (bookingByRouteId?.id) {
        dispatch(setSelectedBooking(bookingByRouteId));
        return navigate("/bookings");
      }
      dispatch(setSelectedBooking({}));
      navigate("/bookings");
    }
  }, [params?.id, bookings?.length]);

  // useEffect(() => {
  //   if (!bookings.length) {
  //     loadData();
  //   }
  // }, [loadData]);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      render: (_, record) => <span>{record?.user?.name}</span>,
      ...getColumnSearchProps(searchInput, "user", "name"),
    },
    {
      title: "Services",
      dataIndex: "bookingServices",
      render: (record) => {
        return record?.map((service, index) => {
          let status = service.discarded
            ? 19
            : service?.disputeReason
            ? 18
            : service?.status;
          let statusBadge = statusBadges[status];

          return (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center mt-1 mb-1"
            >
              <span className="me-1">{service?.service?.name} : </span>
              <h6 className={statusBadge}>{serviceStatuses[status]}</h6>
            </div>
          );
        });
      },
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      render: (bookingDate) => <span>{formatDate(bookingDate)}</span>,
    },
    {
      title: "Booking Time",
      dataIndex: "bookingTime",
      render: (bookingTime) => <span>{bookingTime}</span>,
    },
    {
      title: "Quote",
      dataIndex: "bookingServices",
      render: (record) => {
        return record?.map((service, index) => {
          let statusBadge =
            service?.discarded || service.disputeReason
              ? "badge-delete"
              : "badge-active";
          return (
            <div
              key={index}
              className="d-flex justify-content-between mt-1 mb-1"
            >
              <span className="me-1">{service?.service?.name} : </span>
              <h6 className={statusBadge}>
                $
                {service?.discarded
                  ? 0
                  : service?.assignedQuote?.quoteAmount ?? 0}
              </h6>
            </div>
          );
        });
      },
    },
    {
      title: "Booking Status",
      dataIndex: "status",
      render: (status, record) => {
        const isAllServicesDiscarded = record?.bookingServices?.every(
          (bookingService) => {
            return bookingService?.discarded;
          }
        );

        status = isAllServicesDiscarded
          ? 19
          : +status === 6 && !record?.isCompleted
          ? 5
          : status;

        return (
          <h6 className={statusBadges[status]}>
            {bookingStatuses?.[status]?.value}
          </h6>
        );
      },
      // sorter: (a, b) => a.pending.length - b.pending.length,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="table-actions d-flex">
          {/* <Link
            className="delete-table me-2"
            to="edit"
            state={{ vendor: record }}
          >
            <img src={edit} alt="svg" />
          </Link>
          <Link
            className="delete-table me-2"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-item"
            onClick={(e) => {
              e.preventDefault();
              // setVendorId(record?.id);
            }}
          >
            <img src={Delete} alt="svg" />
          </Link> */}
          <Link
            className="delete-table"
            role="button"
            to="#"
            onClick={() => {
              dispatch(setSelectedBooking(record));
            }}
          >
            <img src={eye} alt="svg" />
          </Link>
        </div>
      ),
      // sorter: (a, b) => a.icon.length - b.icon.length,
    },
  ];

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Booking List</h5>
          {/* <div className="list-btn">
            <ul>
              <li>
                <Link className="btn-filters active" to="/bookings">
                  <i className="fe fe-list">
                    <FeatherIcon icon={"list"} />
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link className="btn-filters" to="/localization">
                  <i className="fe fe-settings">
                    <FeatherIcon icon={"settings"} />
                  </i>{" "}
                </Link>
              </li>
              <li>
                <div className="filter-sorting">
                  <ul>
                    <li>
                      <Link to="#" className="filter-sets">
                        <img src={filter1} className="me-2" alt="img" />
                        Filter
                      </Link>
                    </li>
                    <li>
                      <span>
                        <img src={sort} className="me-2" alt="img" />
                      </span>
                      <div className="review-sort">
                        <SelectBox />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className={classNames("tab-sets", styles.serviceTabs)}>
              <div
                className={classNames("tab-contents-sets", styles.tabContent)}
              >
                <ul className={styles.tabs}>
                  {[
                    {
                      value: "All",
                      path: "/bookings",
                    },
                    ...Object.values(bookingTableTabs ?? {}),
                  ].map((tab) => {
                    return (
                      <li key={tab.key}>
                        <Link
                          to={tab.path}
                          className={classNames(
                            location.pathname === tab.path ? "active" : "",
                            styles.tabLink
                          )}
                        >
                          {tab.value}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className="tab-contents-count">
                <h6>Showing 8-10 of 84 results</h6>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-2 px-md-auto ">
            <div className="table-resposnive table-div pt-2 pt-md-3">
              <div className="table datatable">
                <Table
                  columns={columns}
                  dataSource={bookings}
                  loading={bookingsLoader}
                  rowKey={(record) => record.id}
                  showSizeChanger={true}
                  pagination={{
                    total: bookings?.length ?? 0,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {selectedBooking?.id && (
          <AppDrawer
            open={selectedBooking.id}
            booking={selectedBooking ?? {}}
            resetBookingId={() => dispatch(setSelectedBooking({}))}
          />
        )}
      </div>
    </div>
  );
};
export default AdminBooking;
