// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking_serviceTabs__Tt370 .booking_tabContent__RmrTM .booking_tabs__-bkhm {
  width: 100%;
  flex-wrap: unset;
  column-gap: 1rem;
}
.booking_serviceTabs__Tt370 .booking_tabContent__RmrTM .booking_tabs__-bkhm li {
  padding-left: 0;
}
.booking_serviceTabs__Tt370 .booking_tabLink__uesyu {
  width: max-content;
}

@media screen and (max-width: 576px) {
  .booking_serviceTabs__Tt370 .booking_tabLink__uesyu {
    padding: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/booking/booking.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".serviceTabs .tabContent .tabs {\n  width: 100%;\n  flex-wrap: unset;\n  column-gap: 1rem;\n}\n.serviceTabs .tabContent .tabs li {\n  padding-left: 0;\n}\n.serviceTabs .tabLink {\n  width: max-content;\n}\n\n@media screen and (max-width: 576px) {\n  .serviceTabs .tabLink {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceTabs": `booking_serviceTabs__Tt370`,
	"tabContent": `booking_tabContent__RmrTM`,
	"tabs": `booking_tabs__-bkhm`,
	"tabLink": `booking_tabLink__uesyu`
};
export default ___CSS_LOADER_EXPORT___;
