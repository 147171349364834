// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogs_blogImage__\\+5\\+J\\+{
    position: relative;
    max-height: 200px;
    padding: 0px !important;
    overflow: hidden;
}
.blogs_blogImage__\\+5\\+J\\+ img{
   height: 100%;
   width: 100%;
   max-height: 200px;
   object-fit: cover;
   margin-bottom: 0 !important;
}
.blogs_blogImage__\\+5\\+J\\+ .blogs_blogBannerText__a22xL {
    width: max-content;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/blog/blogs.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;GACG,YAAY;GACZ,WAAW;GACX,iBAAiB;GACjB,iBAAiB;GACjB,2BAA2B;AAC9B;AACA;IACI,kBAAkB;EACpB,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".blogImage{\n    position: relative;\n    max-height: 200px;\n    padding: 0px !important;\n    overflow: hidden;\n}\n.blogImage img{\n   height: 100%;\n   width: 100%;\n   max-height: 200px;\n   object-fit: cover;\n   margin-bottom: 0 !important;\n}\n.blogImage .blogBannerText {\n    width: max-content;\n  position: absolute;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 1;\n  background-color: #fff;\n  padding: 8px 16px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogImage": `blogs_blogImage__+5+J+`,
	"blogBannerText": `blogs_blogBannerText__a22xL`
};
export default ___CSS_LOADER_EXPORT___;
