// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services_removeBtn__-bt-H {
  background-color: #e92b2b;
  border: none;
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 8px 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.services_customBorderStyle__36-Cu {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 0px;
  padding: 20px;
  position: relative;
}
.services_customBorderStyle__36-Cu a:hover svg g{
  color: red;
}
.services_customBorderStyle__36-Cu a:hover svg g line{
  color: red;
}
.services_addBtnsGrid__\\+ODMY {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
.services_addBtnsGrid__\\+ODMY:hover a{
  color: var(--oranger-dark);
}
@media screen and (max-width: 768px) {
  .services_addBtnsGrid__\\+ODMY {
    width: 60%;
  }
}
@media screen and (max-width: 576px) {
  .services_removeBtn__-bt-H {
    font-size: 12px;
    padding: 4px 12px;
  }
  .services_customBorderStyle__36-Cu {
    padding: 10px;
  }
  .services_addBtnsGrid__\\+ODMY {
    width: 100%;
  }
}

.services_serviceTabs__pfaKR .services_tabContent__NSMoU .services_tabs__w2UcX {
  width: 100%;
  flex-wrap: unset;
  column-gap: 1rem;
}
.services_serviceTabs__pfaKR .services_tabContent__NSMoU .services_tabs__w2UcX li {
  padding-left: 0;
}
.services_serviceTabs__pfaKR .services_tabLink__3CqoS {
  width: max-content;
}

@media screen and (max-width: 576px) {
  .services_serviceTabs__pfaKR .services_tabLink__3CqoS {
    padding: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/edit-service/sub-services/services.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".removeBtn {\n  background-color: #e92b2b;\n  border: none;\n  border-radius: 7px;\n  font-size: 14px;\n  font-weight: bold;\n  color: #fff;\n  padding: 8px 16px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n.customBorderStyle {\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  border-radius: 8px;\n  margin: 10px 0px;\n  padding: 20px;\n  position: relative;\n}\n.customBorderStyle a:hover svg g{\n  color: red;\n}\n.customBorderStyle a:hover svg g line{\n  color: red;\n}\n.addBtnsGrid {\n  width: 30%;\n  display: flex;\n  justify-content: space-between;\n  margin: 20px 0px;\n}\n.addBtnsGrid:hover a{\n  color: var(--oranger-dark);\n}\n@media screen and (max-width: 768px) {\n  .addBtnsGrid {\n    width: 60%;\n  }\n}\n@media screen and (max-width: 576px) {\n  .removeBtn {\n    font-size: 12px;\n    padding: 4px 12px;\n  }\n  .customBorderStyle {\n    padding: 10px;\n  }\n  .addBtnsGrid {\n    width: 100%;\n  }\n}\n\n.serviceTabs .tabContent .tabs {\n  width: 100%;\n  flex-wrap: unset;\n  column-gap: 1rem;\n}\n.serviceTabs .tabContent .tabs li {\n  padding-left: 0;\n}\n.serviceTabs .tabLink {\n  width: max-content;\n}\n\n@media screen and (max-width: 576px) {\n  .serviceTabs .tabLink {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeBtn": `services_removeBtn__-bt-H`,
	"customBorderStyle": `services_customBorderStyle__36-Cu`,
	"addBtnsGrid": `services_addBtnsGrid__+ODMY`,
	"serviceTabs": `services_serviceTabs__pfaKR`,
	"tabContent": `services_tabContent__NSMoU`,
	"tabs": `services_tabs__w2UcX`,
	"tabLink": `services_tabLink__3CqoS`
};
export default ___CSS_LOADER_EXPORT___;
