import { useEffect, useMemo } from "react";

import { Table } from "antd";
import bel_icon from "assets/images/icons/bal-icon.svg";
import { useSelector } from "react-redux";
import { asyncGetAllPayments } from "store/payment/paymentThunk";
import { formatDate, toNumber } from "utils";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";

const Earnings = () => {
  // const dispatch = useDispatch();

  const [transactionLoader, fetchTransactions] =
    useLoaderDispatch(asyncGetAllPayments);

  let transactions = useSelector((state) => state.payment.payments);
  transactions = useMemo(
    () => Object.values(transactions ?? {}),
    [transactions]
  );

  const totalEarn = useSelector((state) => state.payment.totalEarn);
  const totalPending = useSelector((state) => state.payment.totalPending);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // const loading = useSelector((state) => state.common.loading);

  // const loadData = useCallback(async () => {
  //   await dispatch(asyncGetAllTransactions()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  useEffect(() => {
    document.body.classList.add("provider-body");

    return () => document.body.classList.remove("provider-body");
  }, []);

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Last Payout Date",
      dataIndex: "transactions",
      render: (transactions) => {
        let lastTransaction = transactions?.at(-1);
        return <span>{formatDate(lastTransaction?.createdAt)}</span>;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "quoteAmount",
      render: (_, record) => (
        <span>
          $
          {(
            toNumber(record?.totalAmount) +
            toNumber(record?.adminCharges) +
            toNumber(record?.tax)
          ).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Amount Pending",
      dataIndex: "quoteAmount",
      render: (_, record) => {
        let totalAmount =
          toNumber(record?.totalAmount) +
          toNumber(record?.adminCharges) +
          toNumber(record?.tax);

        let totalPending = record?.transactions.reduce(
          (acc, transaction) => acc + toNumber(transaction?.paidAmount),
          0
        );
        return <span>${(totalAmount - totalPending).toFixed(2)}</span>;
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "quoteAmount",
      render: (_, record) => {
        let totalPaid = record?.transactions.reduce(
          (acc, transaction) => acc + toNumber(transaction?.paidAmount),
          0
        );
        return <span>${totalPaid.toFixed(2)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        let isCompleted =
          +record?.status === 6 &&
          record?.isCompleted &&
          !record?.pendingPayment;
        return (
          <h6 className={isCompleted ? "badge-active" : "badge-pending"}>
            {isCompleted ? "Paid" : "Pending"}
          </h6>
        );
      },
    },
  ];

  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  console.log(transactions);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="subtitle">
                  <h6>Payout</h6>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {/* Payout card */}
            <div className="col-md-4 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="balance-crad">
                    <div className="balance-head">
                      <span className="balance-icon">
                        <img src={bel_icon} alt="" />
                      </span>
                      <div className="balance-info">
                        <h6>Total Earn</h6>
                        <h3>
                          $
                          <span className="counters" data-count={180}>
                            {transactionLoader ? "Loading..." : totalEarn}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="balance-crad">
                    <div className="balance-head">
                      <span className="balance-icon">
                        <img src={bel_icon} alt="" />
                      </span>
                      <div className="balance-info">
                        <h6>Pending Payments</h6>
                        <h3>
                          $
                          <span className="counters" data-count={200}>
                            {transactionLoader ? "Loading..." : totalPending}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /Payout card */}
          </div>
          {/* Payout History */}
          <div className="row">
            <div className="col-md-12">
              <h6 className="subhead-title">Payout History</h6>
              <div className="provide-table manage-table">
                <div className="table-responsive">
                  <div className="table datatable">
                    <Table
                      columns={columns}
                      dataSource={transactions}
                      loading={transactionLoader}
                      rowKey={(record) => record.id}
                      showSizeChanger={true}
                      pagination={{
                        total: transactions?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                  {/* <table
                    className="table custom-table datatable"
                    id="data-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Payout Date</th>
                        <th>Amount</th>
                        <th>Refunds</th>
                        <th>Fees</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Payment Processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sep 26, 2022</td>
                        <td>$100.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge badge-warning">Pending</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td className="text-light-danger">-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div id="tablelength" />
                </div>
                <div className="col-md-9">
                  <div className="table-ingopage">
                    <div id="tableinfo" />
                    <div id="tablepagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Payout History */}
        </div>
      </div>
    </>
  );
};
export default Earnings;
