import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: {},
  payments: {},
  totalEarn: 0,
  totalPending: 0,
};

const TransactionSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setTransactions: (state, { payload }) => {
      state.transactions = payload?.reduce(
        (acc, transaction) => ({ ...acc, [transaction.id]: transaction }),
        {}
      );
    },
    setPayments: (state, { payload }) => {
      state.payments = payload.payments?.reduce(
        (acc, payment) => ({ ...acc, [payment.id]: payment }),
        {}
      );
      state.totalEarn = payload.totalEarn;
      state.totalPending = payload.totalPending;
    },
    setPayment: (state, { payload }) => {
      if (!state.payments["payment_" + payload.id]) {
        state.payments = {
          ["payment_" + payload.id]: payload,
          ...state.payments,
        };
      } else {
        state.payments["payment_" + payload.id] = payload;
      }
    },
    setTransaction: (state, { payload }) => {
      if (!state.transactions["transaction_" + payload.id]) {
        state.transactions = {
          ["transaction_" + payload.id]: payload,
          ...state.transactions,
        };
      } else {
        state.transactions["transaction_" + payload.id] = payload;
      }
    },
  },
});

export const { setPayments, setPayment, setTransactions, setTransaction } =
  TransactionSlice.actions;
export default TransactionSlice.reducer;

export const getTotalRevenue = (state) => state.payment.totalEarn;

export const getRecentTransactions = createDraftSafeSelector(
  (state) => state.payment.transactions,
  (transactions) => {
    return Object.values(transactions ?? {}).slice(0, 10);
  }
);
