import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const ConfirmModal = ({ id, title, msg, onCancel, onDone }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      aria-labelledby={"modalLabel-" + id}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={"modalLabel-" + id}>
              {title}
            </h5>
            <button
              type="button"
              className="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fe fe-x">
                <FeatherIcon icon={"x"} />
              </i>
            </button>
          </div>
          <div className="modal-body py-0">
            <div className="del-modal">
              <p>{msg}</p>
            </div>
          </div>
          <div className="modal-footer pt-0">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={onDone}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
