import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { asyncAlertError, asyncAlertSuccess } from "../common/commonThunk";
import { setLoading } from "../common/commonSlice";
import { setSubServices } from "./subServiceSlice";

export const asyncGetAllSubServices = createAsyncThunk(
  "service/sub-services/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/sub-services",
        method: "GET",
        token,
      });

      let dataSource = {};
      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          // let status = curr.active ? "sub_services" : "inactive";
          let status = !curr.serviceTrashed ? "sub_services" : "inactive";
          return {
            ...acc,
            [status]: {
              ...(acc[status] || {}),
              ["sub_service_" + curr.id]: curr,
            },
          };
        }, {});

        dispatch(setSubServices({ ...dataSource }));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncGetSubServicesByServiceId = createAsyncThunk(
  "service/sub-services/list/id",
  async (serviceId, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: `users/sub-services/${serviceId}`,
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setSubServices(res.data));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncCreateSubService = createAsyncThunk(
  "service/sub-services/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/sub-services`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res?.message ?? ""));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateSubService = createAsyncThunk(
  "service/sub-services/update",
  async ({ subServiceId, body }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/sub-services/${subServiceId}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res?.message ?? ""));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
