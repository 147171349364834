import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import AdminHeader from "commoncomponent/AdminHeader";
import AddVendor from "components/Users/AddVendor";
import Customers from "components/Users/Customers";
import Vendors from "components/Users/Vendors";
// import EditCustomer from "components/Users/editCustomer";
import AdminBooking from "components/booking";
// import BookingCancelled from "components/booking/cancelled";
// import BookingCompleted from "components/booking/completed";
// import AdminBookingInprogress from "components/booking/inprogress";
// import AdminBookingPending from "components/booking/pending";
import AdminDasBoard from "components/dashboard";

import AddService from "components/edit-service";

import MainSidebar from "components/mainSidebar/MainSidebar";
import AdminServices from "./admin-service/adminServices";

import SignIn from "screens/login";
import ForgotPassword from "screens/forgotPassword";
// import EditBooking from "components/edit-service/booking";
// import EditAvailabilty from "components/edit-service/availabilty";
// import EditGallary from "components/edit-service/editgallary";
// import EditInformation from "components/edit-service/information";
import AddSubService from "components/edit-service/sub-services/addSubService";
// import InactiveServices from "components/edit-service/sub-services/inactiveServices";
// import PendingServices from "components/edit-service/sub-services/pendingServices";
import SubService from "components/edit-service/sub-services/subService";

import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "store/auth/authSlice";
import { APP_URL } from "services/axios";

import { io } from "socket.io-client";
import { useCallback, useEffect } from "react";
import { removeService, setService } from "store/service/serviceSlice";
import {
  removeSubService,
  setSubService,
} from "store/subService/subServiceSlice";
import {
  removeVendor,
  setVendor,
  setVendorProfile,
} from "store/vendor/vendorSlice";
import {
  setBooking,
  setQuotes,
  setServiceBookingProperties,
} from "store/booking/bookingSlice";
import { removeCustomer, setCustomer } from "store/customer/customerSlice";
import AdminSettings from "components/adminSettings";
import { setPayment } from "store/payment/paymentSlice";
import Earnings from "screens/vendor/Earnings";

import { setLoading } from "store/common/commonSlice";
import PageLoader from "components/PageLoader";
import { InternetStatus } from "components/InternetStatus";
import AdminAddBlog from "components/blog/addblog";
import AdminAllBlog from "components/blog/allblog";
import ResetPassword from "screens/resetPassword";
// import PageLoader from "components/PageLoader";

export const ProtectedLayout = () => {
  const token = useSelector(selectToken);
  const location = useLocation();
  const loading = useSelector((state) => state.common.loading);

  if (!token) {
    if (location?.pathname?.includes("bookings")) {
      return <Navigate to={`/login?callbackUrl=${location.pathname}`} />;
    } else {
      return <Navigate to="/login" />;
    }
  }
  return (
    <>
      <AdminHeader />
      <MainSidebar />
      <InternetStatus />
      {loading && <PageLoader />}
      <Outlet />
    </>
  );
};

const socket = io(APP_URL);

function App() {
  const dispatch = useDispatch();

  const handleCustomer = useCallback(
    (record) => {
      record?.deleted
        ? dispatch(removeCustomer(record))
        : dispatch(setCustomer(record));
    },
    [dispatch]
  );

  const handleServiceAndSubService = useCallback(
    (record) => {
      if (record.parentId) {
        record?.deleted
          ? dispatch(removeSubService(record))
          : dispatch(setSubService(record));
      } else {
        record?.deleted
          ? dispatch(removeService(record))
          : dispatch(setService(record));
      }
    },
    [dispatch]
  );

  const handleVendor = useCallback(
    (record) => {
      record?.deleted
        ? dispatch(removeVendor(record))
        : dispatch(setVendor(record));
    },
    [dispatch]
  );

  // const handleVendorProfile = useCallback(
  //   (record) => {
  //     dispatch(setVendorProfile(record));
  //   },
  //   [dispatch]
  // );

  const handleBooking = useCallback(
    (record) => {
      dispatch(setBooking(record));
    },
    [dispatch]
  );

  const handleQuotes = useCallback(
    (record) => {
      dispatch(setQuotes(record));
    },
    [dispatch]
  );

  const handleServiceInProgress = useCallback(
    (record) => {
      let { booking, ...data } = record;

      dispatch(
        setQuotes({
          bookingId: booking?.id,
          serviceBookingId: record?.id,
          bookingStatus: booking?.status,
          ...data,
        })
      );
    },
    [dispatch]
  );

  const handleServiceCompleted = useCallback(
    (record) => {
      let { booking, ...data } = record;

      dispatch(
        setQuotes({
          bookingId: booking?.id,
          bookingStatus: booking?.status,
          serviceBookingId: record?.id,
          ...data,
        })
      );
    },
    [dispatch]
  );

  const handleServiceDispute = useCallback(
    (record) => {
      let { booking, ...data } = record;
      dispatch(
        setQuotes({
          bookingId: booking?.id,
          bookingStatus: booking?.status,
          serviceBookingId: record?.id,
          ...data,
        })
      );
    },
    [dispatch]
  );

  const handleTransaction = useCallback(
    (record) => {
      dispatch(setPayment(record));
    },
    [dispatch]
  );

  const handleServiceDiscarded = useCallback(
    (record) => {
      dispatch(
        setServiceBookingProperties({
          ...record,
          param: {
            discarded: true,
          },
        })
      );
    },
    [dispatch]
  );

  const handleDisputeResolved = useCallback(
    (record) => {
      dispatch(
        setServiceBookingProperties({
          ...record,
          param: { disputeReason: null, status: 6 },
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    socket.on(`user/registration`, handleCustomer);
    socket.on(`user/profile/update`, handleCustomer);
    socket.on(`user/delete`, handleCustomer);

    socket.on(`service/update`, handleServiceAndSubService);
    socket.on(`service/delete`, handleServiceAndSubService);
    socket.on(`subService/update`, handleServiceAndSubService);
    socket.on(`update/featuredService`, handleServiceAndSubService);

    socket.on(`vendor/registration`, handleVendor);
    socket.on(`admin/vendorProfile/update`, handleVendor);
    socket.on(`vendor/profile/update`, handleVendor);

    socket.on(`create/booking`, handleBooking);
    socket.on(`serviceBooking/quoteCreated`, handleQuotes);
    socket.on(`admin/vendor/quoteUpdated`, handleQuotes);
    socket.on("admin/quoteSelected", handleQuotes);
    socket.on("admin/bookingCommission", handleBooking);

    socket.on(`serviceBooking/inProgress`, handleServiceInProgress);
    socket.on(`serviceBooking/completed`, handleServiceCompleted);

    socket.on("serviceBooking/dispute", handleServiceDispute);

    socket.on(`booking/status`, handleBooking);

    socket.on("admin/serviceBooking/discarded", handleServiceDiscarded);

    socket.on("admin/serviceBooking/dispute/resolved", handleDisputeResolved);

    // socket.on("admin/transaction", handleTransaction);

    return () => {
      socket.off(`user/registration`, handleCustomer);
      socket.off(`user/profile/update`, handleCustomer);
      socket.off(`user/delete`, handleCustomer);

      socket.off(`service/update`, handleServiceAndSubService);
      socket.off(`service/delete`, handleServiceAndSubService);
      socket.off(`subService/update`, handleServiceAndSubService);
      socket.off(`update/featuredService`, handleServiceAndSubService);

      socket.off(`vendor/registration`, handleVendor);
      socket.off(`admin/vendorProfile/update`, handleVendor);
      socket.off(`vendor/profile/update`, handleVendor);

      socket.off(`create/booking`, handleBooking);
      socket.off(`serviceBooking/quoteCreated`, handleQuotes);
      socket.off(`admin/vendor/quoteUpdated`, handleQuotes);
      socket.off("admin/quoteSelected", handleQuotes);
      socket.off("admin/bookingCommission", handleBooking);

      socket.off(`serviceBooking/inProgress`, handleServiceInProgress);
      socket.off(`serviceBooking/completed`, handleServiceCompleted);

      socket.off("serviceBooking/dispute", handleServiceDispute);

      socket.off(`booking/status`, handleBooking);

      socket.off("admin/serviceBooking/discarded", handleServiceDiscarded);

      socket.off(
        "admin/serviceBooking/dispute/resolved",
        handleDisputeResolved
      );

      // socket.off("admin/transaction", handleTransaction);
    };
  }, [
    handleCustomer,
    handleServiceAndSubService,
    handleServiceAndSubService,
    handleVendor,
    // handleVendorProfile,
    handleBooking,
    handleQuotes,
    handleServiceInProgress,
    handleServiceCompleted,
    handleServiceDispute,
    handleTransaction,
    handleServiceDiscarded,
    handleDisputeResolved,
  ]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 2000);
  }, []);

  return (
    <div className="main-wrapper" id="session">
      <Routes>
        <Route path="/" element={<ProtectedLayout />}>
          <Route index element={<AdminDasBoard />} />

          <Route path="services">
            <Route index element={<AdminServices />} />
            <Route path="add" element={<AddService />} />
            <Route path=":id" element={<AddService />} />

            <Route path="inactive">
              <Route index element={<AdminServices />} />
              <Route path=":id" element={<AddService />} />
            </Route>
          </Route>

          <Route path="sub_services">
            <Route index element={<SubService />} />
            <Route path="add" element={<AddSubService />} />
            <Route path=":id" element={<AddSubService />} />

            <Route path="inactive">
              <Route index element={<SubService />} />
              <Route path=":id" element={<AddSubService />} />
            </Route>
          </Route>
          <Route path="blogs">
            <Route index element={<AdminAllBlog />} />
            <Route path="add" element={<AdminAddBlog />} />
            <Route path=":id" element={<AdminAddBlog />} />
          </Route>

          <Route path="vendors">
            <Route index element={<Vendors />} />
            <Route path="add" element={<AddVendor />} />
            <Route path=":id" element={<AddVendor />} />

            <Route path="pending">
              <Route index element={<Vendors />} />
              <Route path=":id" element={<AddVendor />} />
            </Route>

            <Route path="inactive">
              <Route index element={<Vendors />} />
              <Route path=":id" element={<AddVendor />} />
            </Route>
          </Route>

          <Route path="customers">
            <Route index element={<Customers />} />
          </Route>

          <Route path="bookings">
            <Route index element={<AdminBooking />} />
            <Route path=":id" element={<AdminBooking />} />
            <Route path="pending" element={<AdminBooking />} />
            <Route path="decision" element={<AdminBooking />} />
            <Route path="accepted" element={<AdminBooking />} />
            <Route path="declined" element={<AdminBooking />} />
            <Route path="inprogress" element={<AdminBooking />} />
            <Route path="completed" element={<AdminBooking />} />
            <Route path="disputed" element={<AdminBooking />} />
            <Route path="*" element={<Navigate to="/bookings" />} />
          </Route>

          <Route path="/earnings" element={<Earnings />} />
          <Route path="/settings" element={<AdminSettings />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
