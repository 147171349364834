import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setCustomers } from "./customerSlice";

export const asyncGetAllCustomers = createAsyncThunk(
  "customer/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/users",
        method: "GET",
        token,
      });

      if (res.success) {
        const customers = res.data.reduce((acc, curr) => {
          return {
            ...acc,
            ["customer_" + curr.id]: curr,
          };
        }, {});

        dispatch(setCustomers(customers));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);
