// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-header #mobile_btn{
    display: none;
}
@media screen and (max-width:991px){
    .sidebar-header #mobile_btn{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/mainSidebar/mainSidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".sidebar-header #mobile_btn{\n    display: none;\n}\n@media screen and (max-width:991px){\n    .sidebar-header #mobile_btn{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
