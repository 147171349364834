import { Form, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PhoneInput, { phoneInputValidator } from "commoncomponent/PhoneInput";
import Select from "commoncomponent/Select/Select";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL_IMAGE } from "services/axios";
import { asyncAlertError } from "store/common/commonThunk";
import { asyncGetAllServices } from "store/service/serviceThunk";
import {
  asyncCreateVendor,
  asyncGetAllVendors,
  asyncUpdateVendor,
} from "store/vendor/vendorThunk";
import _ from "lodash";

const AddVendor = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  // const [showPass, setShowPass] = useState(true);

  let [parentRoute, childRoute, routeId] = pathname.split("/").filter((x) => x);

  const active = Form.useWatch("active", form);

  let vendors = useSelector((state) => state.vendor.vendors);
  vendors = useMemo(() => Object.values(vendors ?? {}), [vendors]);

  let services = useSelector((state) => state.service.services);
  services = useMemo(() => Object.values(services ?? {}), [services]);

  const loading = useSelector((state) => state.vendor.loading);

  let vendor = useSelector((state) => {
    if (routeId) {
      return state?.vendor?.[childRoute]?.["vendor_" + id];
    } else if (!routeId) {
      return state?.vendor?.[parentRoute]?.["vendor_" + id];
    }
    return {};
  });

  let vendorExistingService = useMemo(
    () => vendor?.vendorServices?.map((item) => item.serviceId),
    [id, vendor?.vendorServices]
  );

  let EditServices = useMemo(() => {
    if (id) {
      return services
        .filter((service) => vendorExistingService?.includes(service.id))
        .map((service) => ({
          label: service.name,
          value: service.id,
        }));
    }
    return [];
  }, [id, vendorExistingService]);

  const loadData = useCallback(async () => {
    dispatch(asyncGetAllServices());
    dispatch(asyncGetAllVendors());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name: vendor?.name,
        serviceId: EditServices,
        email: vendor?.email,
        mobileNumber: vendor?.mobileNumber,
        active: vendor?.active,
        file: {
          uid: "-1",
          name: "downloaded.png",
          active: "done",
          url: BASE_URL_IMAGE + vendor?.image,
        },
      });
      setFileList([
        {
          uid: "-1",
          name: "downloaded.png",
          active: "done",
          url: BASE_URL_IMAGE + vendor?.image,
        },
      ]);
      form.validateFields(["file"]);
    }

    return () => {
      form.resetFields();
      setFileList([]);
    };
  }, [id, EditServices, vendor?.updatedAt]);

  const onFinish = async (values) => {
    let body = {};
    let dataToSend = new FormData();

    const serviceId = values.serviceId.map((item) => item.value);

    if (values.name !== vendor?.name) {
      body.name = values.name;
      dataToSend.append("name", values.name);
    }

    if (!id && values.email !== vendor?.email) {
      body.email = values.email;
      dataToSend.append("email", values.email);
    }

    if (values.mobileNumber !== vendor?.mobileNumber) {
      body.mobileNumber = values.mobileNumber;
      dataToSend.append("mobileNumber", values.mobileNumber);
    }

    values.active = values.active ?? false;
    if (values.active !== vendor?.active) {
      body.active = values.active;
      dataToSend.append("active", values.active);
    }

    if (fileList[0]?.originFileObj) {
      body.file = values.file;
      dataToSend.append("file", values.file);
    }

    if (_.difference(serviceId, vendorExistingService).length) {
      body.serviceId = JSON.stringify(serviceId);
      dataToSend.append("serviceId", JSON.stringify(serviceId));
    }

    const isFormData = !!fileList[0]?.originFileObj;

    let res = {};

    if (Object.keys(body).length === 0)
      return dispatch(asyncAlertError("No changes have been made"));

    if (id) {
      res = await dispatch(
        asyncUpdateVendor({
          vendorId: vendor?.id,
          body: isFormData ? dataToSend : body,
          isFormData,
        })
      ).unwrap();
    } else {
      res = await dispatch(asyncCreateVendor(dataToSend)).unwrap();
    }

    if (res.success) {
      return navigate(values.active ? "/vendors" : "/vendors/inactive");
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({
      file: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["file"]);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <Form
            form={form}
            name="vendor"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{
              name: "",
              email: "",
              mobileNumber: "",
              file: null,
              active: false,
              serviceId: [],
            }}
          >
            <div className="row">
              <div className="col-xl-7 col-lg-10 col-md-8 col-sm-12 m-auto">
                <div className="content-page-header">
                  {!id ? <h5>Add New Vendor</h5> : <h5>Edit Vendor</h5>}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Item
                      name="file"
                      validateFirst={true}
                      rules={[
                        {
                          required: true,
                          message: `Image is required!`,
                        },
                      ]}
                    >
                      <ImgCrop accept=".png,.jpeg,.jpg">
                        <Upload
                          multiple={false}
                          listType="picture-card"
                          customRequest={({ onSuccess }) => onSuccess("ok")}
                          fileList={fileList}
                          showUploadList={true}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          maxCount={1}
                        >
                          {fileList.length === 1 ? null : "Upload"}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    {/* <div className="profile-upload">
                      <div className="profile-upload-img">
                        <img src={user_01} alt="img" id="blah" />
                      </div>
                      <div className="profile-upload-content">
                        <div className="profile-upload-btn">
                          <div className="profile-upload-file">
                            <input type="file" id="imgInp" />
                            <Link to="#" className="btn btn-upload">
                              Upload
                            </Link>
                          </div>
                          <Link to="#" className="btn btn-remove">
                            Remove
                          </Link>
                        </div>
                        <div className="profile-upload-para">
                          <p>
                            *image size should be at least 320px big,and less
                            then 500kb. Allowed files .png and .jpg.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Name</label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Name is required!",
                          },
                        ]}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Name"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Select Service</label>
                      <Form.Item
                        name="serviceId"
                        rules={[
                          {
                            required: true,
                            message: "Service is required!",
                          },
                        ]}
                      >
                        <Select
                          isDisabled={!services.length || loading}
                          isMulti={true}
                          options={services.map((service) => ({
                            label: service.name,
                            value: service.id,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email</label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            whitespace: true,
                            message: "Email is required!",
                          },
                        ]}
                      >
                        <input
                          disabled={!!id}
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Phone</label>
                      <Form.Item
                        name="mobileNumber"
                        validateFirst
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Phone is required!",
                          },
                          phoneInputValidator,
                        ]}
                      >
                        <PhoneInput />
                      </Form.Item>
                    </div>
                  </div>
                  {!!id && (
                    <div className="col-12">
                      <div className="form-groupheads d-flex d-flex justify-content-between">
                        <h2>Status</h2>
                        <div className="active-switch">
                          <Form.Item
                            name="active"
                            valuePropName="checked"
                            rules={[
                              {
                                required: true,
                                message: "Status is required!",
                              },
                            ]}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={active ?? false}
                                onChange={(e) => {
                                  form.setFieldsValue({
                                    active: e.target.checked,
                                  });
                                  form.validateFields(["active"]);
                                }}
                              />
                              <span className="sliders round" />
                            </label>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-12">
                    <div className="btn-path">
                      <Link to="/vendors" className="btn btn-cancel me-3">
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary ">
                        {id ? "Save Changes" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};
export default AddVendor;
