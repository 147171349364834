import { Table } from "antd";
import Delete from "assets/images/icons/delete.svg";
import edit from "assets/images/icons/edit.svg";
import filter1 from "assets/images/icons/filter1.svg";
import sort from "assets/images/icons/sort.svg";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SelectBox from "react-select";
import { BASE_URL_IMAGE } from "services/axios";
import {
  asyncDeleteVendor,
  asyncGetAllVendors,
} from "store/vendor/vendorThunk";
import { formatDate, vendorTableTabs, getColumnSearchProps } from "utils";
import user_01 from "assets/images/customer/user-01.jpg";
import styles from "./vendor.module.css";
import classNames from "classnames";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";
import { formatPhoneNumber } from "react-phone-number-input";

const AdminProvider = () => {
  // const [vendorId, setVendorId] = useState(null);
  // const loading = useSelector((state) => state.common.loading);

  const [vendorLoader, fetchAllVendors] = useLoaderDispatch(asyncGetAllVendors);

  // const dispatch = useDispatch();
  const location = useLocation();

  let route = location.pathname
    .split("/")
    .filter((x) => x)
    .pop();

  let vendors = useSelector((state) => {
    if (route) {
      return state.vendor?.[route];
    }
    return [];
  });

  vendors = useMemo(() => Object.values(vendors ?? {}), [vendors]);

  useEffect(() => {
    fetchAllVendors();
  }, [fetchAllVendors]);

  // const loadData = useCallback(async () => {
  //   dispatch(asyncGetAllVendors());
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  // const handleDeleteVendor = async () => {
  //   let res = await dispatch(asyncDeleteVendor(vendorId)).unwrap();
  //   if (res.success) {
  //     setVendorId(null);
  //   }
  // };
  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      render: (_, record) => (
        <div className="table-namesplit">
          <div className="table-profileimage">
            <img
              src={record.image ? BASE_URL_IMAGE + record.image : user_01}
              className="me-2"
              alt="img"
            />
          </div>
          <div className="table-name">
            <span>{record?.name}</span>
            <p>{record.email}</p>
          </div>
        </div>
      ),
      ...getColumnSearchProps(searchInput, "name"),
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      render: (mobileNumber) => (
        <span>{formatPhoneNumber(mobileNumber) || "N/A"}</span>
      ),
    },
    {
      title: "Reg Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Services",
      dataIndex: "vendorServices",
      render: (vendorServices) => {
        return vendorServices.length > 0 ? (
          vendorServices?.map(({ service }, index) => {
            return (
              <div
                key={service?.id}
                className={`table-namesplit ${index > 0 ? "mt-4" : ""}`}
              >
                <div className="table-profileimage">
                  <img
                    src={
                      service.image ? BASE_URL_IMAGE + service.image : user_01
                    }
                    className="me-2"
                    alt="img"
                  />
                </div>
                <div className="table-name">
                  <span>{service?.name}</span>
                  <p>{service.email}</p>
                </div>
              </div>
            );
          })
        ) : (
          <span>N/A</span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => (
        <h6 className={active ? "badge-active" : "badge-inactive"}>
          {active ? "Active" : "In Active"}
        </h6>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="table-actions d-flex">
          <Link className="delete-table me-2" to={`${record?.id}`}>
            <img src={edit} alt="svg" />
          </Link>
          {/* <Link
            className="delete-table"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-item"
            onClick={(e) => {
              e.preventDefault();
              setVendorId(record?.id);
            }}
          >
            <img src={Delete} alt="svg" />
          </Link> */}
        </div>
      ),
      // sorter: (a, b) => a.icon.length - b.icon.length,
    },
  ];

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>All Vendors</h5>
            <div className="list-btn">
              <ul>
                {/* <li>
                  <Link className="btn-filters active" to="/vendors">
                    <i className="fe fe-list">
                      <FeatherIcon icon={"list"} />
                    </i>{" "}
                  </Link>
                </li>
                <li>
                  <Link className="btn-filters" to="/localization">
                    <i className="fe fe-settings">
                      <FeatherIcon icon={"settings"} />
                    </i>{" "}
                  </Link>
                </li>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <img src={filter1} className="me-2" alt="img" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <img src={sort} className="me-2" alt="img" />
                        </span>
                        <div className="review-sort">
                          <SelectBox />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li> */}
                {route === "vendors" && (
                  <li>
                    <Link className="btn btn-primary" to="/vendors/add">
                      <i className="fa fa-plus me-2" />
                      Add Vendor
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={classNames("tab-sets", styles.serviceTabs)}>
                <div
                  className={classNames("tab-contents-sets", styles.tabContent)}
                >
                  <ul className={styles.tabs}>
                    {vendorTableTabs.map((tab) => (
                      <li key={tab.key}>
                        <Link
                          to={tab.path}
                          className={classNames(
                            location.pathname === tab.path ? "active" : "",
                            styles.tabLink
                          )}
                        >
                          {tab.value}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="tab-contents-count">
                  <h6>Showing 8-10 of 84 results</h6>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-2 px-md-auto ">
              <div className="table-resposnive table-div pt-2 pt-md-3">
                <div className="table datatable">
                  <Table
                    columns={columns}
                    loading={vendorLoader}
                    dataSource={vendors}
                    rowKey={(record) => record.id}
                    showSizeChanger={true}
                    pagination={{
                      total: vendors.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete */}
      {/* <div
        className="modal fade"
        id="delete-item"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Providers
              </h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fe fe-x">
                  <FeatherIcon icon={"x"} />
                </i>
              </button>
            </div>
            <form action="user-providers">
              <div className="modal-body py-0">
                <div className="del-modal">
                  <p>Are you sure want to Delete?</p>
                </div>
              </div>
              <div className="modal-footer pt-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setVendorId(null)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      {/* /Delete */}
    </>
  );
};
export default AdminProvider;
