// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminService_serviceTabs__X4FNw .adminService_tabContent__uPwzZ .adminService_tabs__-PM3o{
    width: 100%;
    flex-wrap: unset;
}
.adminService_serviceTabs__X4FNw .adminService_tabLink__CtWOA{
    width: max-content;
    /* background-color: red; */
}`, "",{"version":3,"sources":["webpack://./src/admin-service/adminService.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B","sourcesContent":[".serviceTabs .tabContent .tabs{\n    width: 100%;\n    flex-wrap: unset;\n}\n.serviceTabs .tabLink{\n    width: max-content;\n    /* background-color: red; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceTabs": `adminService_serviceTabs__X4FNw`,
	"tabContent": `adminService_tabContent__uPwzZ`,
	"tabs": `adminService_tabs__-PM3o`,
	"tabLink": `adminService_tabLink__CtWOA`
};
export default ___CSS_LOADER_EXPORT___;
