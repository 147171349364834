import { Table } from "antd";
import filter1 from "assets/images/icons/filter1.svg";
import sort from "assets/images/icons/sort.svg";
import AdminUsers from "assets/json/user";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import SelectBox from "react-select";
import { BASE_URL_IMAGE } from "services/axios";
import { asyncGetAllCustomers } from "store/customer/customerThunk";
import { formatDate, getColumnSearchProps } from "utils";

// import Delete from "assets/images/icons/delete.svg";
import user_01 from "assets/images/customer/user-01.jpg";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";
import { formatPhoneNumber } from "react-phone-number-input";
// import edit from "assets/images/icons/edit.svg";

const AdminCustomer = () => {
  // const data = AdminUsers.AdminUsers;
  // const dispatch = useDispatch();

  // const loading = useSelector((state) => state.common.loading);

  const [customersLoader, fetchAllCustomers] =
    useLoaderDispatch(asyncGetAllCustomers);

  let customers = useSelector((state) => state.customer.customers);
  customers = useMemo(() => Object.values(customers ?? {}), [customers]);

  useEffect(() => {
    fetchAllCustomers();
  }, [fetchAllCustomers]);

  // const loadData = useCallback(async () => {
  //   dispatch(asyncGetAllCustomers());
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Customer",
      dataIndex: "name",
      render: (_, record) => (
        <div className="table-namesplit">
          <div className="table-profileimage">
            <img
              src={record?.image ? BASE_URL_IMAGE + record.image : user_01}
              className="me-2"
              alt="img"
            />
          </div>
          <div className="table-name">
            <span>{record?.name}</span>
            <p>{record.email}</p>
          </div>
        </div>
      ),
      ...getColumnSearchProps(searchInput, "name"),
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      render: (mobileNumber) => (
        <span>{formatPhoneNumber(mobileNumber) || "N/A"}</span>
      ),
    },
    {
      title: "Reg Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => (
        <h6 className={active ? "badge-active" : "badge-inactive"}>
          {active ? "Active" : "In Active"}
        </h6>
      ),
    },
    // {
    //   title: "Last Activity",
    //   dataIndex: "pending",
    //   render: (_) => (
    //     <div className={"online-set"}>
    //       <span className={"online-path"} />
    //       <h6>{"Online"}</h6>
    //     </div>
    //   ),
    // sorter: (a, b) => a.seen.length - b.seen.length,
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (_, record) => (
    //     <div className="table-actions d-flex">
    //       <Link
    //         className="delete-table me-2"
    //         to="edit"
    //         state={{ vendor: record }}
    //       >
    //         <img src={edit} alt="svg" />
    //       </Link>
    //       <Link
    //         className="delete-table"
    //         to="#"
    //         data-bs-toggle="modal"
    //         data-bs-target="#delete-item"
    //       >
    //         <img src={Delete} alt="svg" />
    //       </Link>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.icon.length - b.icon.length,
    // },
  ];

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>All Customers</h5>
            {/* <div className="list-btn">
              <ul>
                <li>
                  <Link className="btn-filters active" to="/user-customers">
                    <i className="fe fe-list">
                      <FeatherIcon icon={"list"} />
                    </i>
                  </Link>
                </li>
                <li>
                  <Link className="btn-filters" to="/localization">
                    <i className="fe fe-settings">
                      <FeatherIcon icon={"settings"} />
                    </i>
                  </Link>
                </li>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <img src={filter1} className="me-2" alt="img" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <img src={sort} className="me-2" alt="img" />
                        </span>
                        <div className="review-sort">
                          <SelectBox />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-resposnive">
                <div className="table datatable">
                  <Table
                    columns={columns}
                    dataSource={customers}
                    loading={customersLoader}
                    rowKey={(record) => record.id}
                    showSizeChanger={true}
                    pagination={{
                      total: customers?.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete */}
      <div
        className="modal fade"
        id="delete-item"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Customers
              </h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fe fe-x">
                  <FeatherIcon icon={"x"} />
                </i>
              </button>
            </div>
            <form action="user-customers">
              <div className="modal-body py-0">
                <div className="del-modal">
                  <p>Are you sure want to Delete?</p>
                </div>
              </div>
              <div className="modal-footer pt-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete */}
    </>
  );
};
export default AdminCustomer;
