// import upload from "assets/images/icons/upload.svg";
import TextEditor from "components/editor/editor";

// import service_01 from "assets/images/services/service-01.jpg";
// import service_02 from "assets/images/services/service-02.jpg";
// import service_03 from "assets/images/services/service-03.jpg";
// import service_04 from "assets/images/services/service-04.jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCreateService,
  asyncUpdateService,
} from "store/service/serviceThunk";
import { useEffect, useState } from "react";
import { BASE_URL_IMAGE } from "services/axios";
// import { getServicesByRouteAndId } from "store/service/serviceSlice";
// import ImageUploadAndPreview from "components/ImageUploadAndPreview";

const AddService = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  let [parentRoute, childRoute, routeId] = pathname.split("/").filter((x) => x);

  // const service = useSelector((state) =>
  //   getServicesByRouteAndId(state, {
  //     route: routeId ? childRoute : parentRoute,
  //     serviceId: id,
  //   })
  // );

  const service = useSelector((state) => {
    if (routeId) {
      return state?.service?.[childRoute]?.["service_" + id];
    } else if (!routeId) {
      return state?.service?.[parentRoute]?.["service_" + id];
    }
    return {};
  });

  const active = Form.useWatch("active", form);
  const isToAndFrom = Form.useWatch("isToAndFrom", form);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name: service?.name,
        description: service?.description,
        active: service?.active,
        isToAndFrom: service?.isToAndFrom,
        file: {
          uid: "-1",
          name: "downloaded.png",
          active: "done",
          url: BASE_URL_IMAGE + service?.image,
        },
      });
      setFileList([
        {
          uid: "-1",
          name: "downloaded.png",
          active: "done",
          url: BASE_URL_IMAGE + service?.image,
        },
      ]);
      form.validateFields(["file"]);
    }

    return () => {
      form.resetFields();
      setFileList([]);
    };
  }, [id]);

  const onFinish = async (values) => {
    let res = {};

    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("active", values.active);
    formData.append("isToAndFrom", values.isToAndFrom);

    if (fileList[0]?.originFileObj) {
      formData.append("file", values.file);
    }

    if (id) {
      res = await dispatch(
        asyncUpdateService({ serviceId: service?.id, body: formData })
      ).unwrap();
    } else {
      res = await dispatch(asyncCreateService(formData)).unwrap();
    }

    if (res.success) {
      return navigate("/services");
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    console.log(newFileList, "aaa");
    setFileList(newFileList);
    form.setFieldsValue({
      file: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["file"]);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          {!id ? <h5>Add New Service</h5> : <h5>Edit Service</h5>}
        </div>
        <div className="row">
          <div className="col-lg-12 m-auto">
            <Form
              form={form}
              name="service"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                file: null,
                name: "",
                description: "",
                active: false,
                isToAndFrom: false,
              }}
            >
              <fieldset id="first-field">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="form-uploads mb-4">
                        <div className="form-uploads-path">
                          <img src={upload} alt="img" />
                          <div className="file-browse">
                            <h6>Drag &amp; drop image or </h6>
                            <div className="file-browse-path">
                              <input type="file" />
                              <Link to="#"> Browse</Link>
                            </div>
                          </div>
                          <h5>Supported formates: JPEG, PNG</h5>
                        </div>
                      </div> */}
                    <div>
                      <ul>
                        <li>
                          <Form.Item
                            name="file"
                            validateFirst={true}
                            rules={[
                              {
                                required: true,
                                message: `Image is required!`,
                              },
                            ]}
                          >
                            <ImgCrop fillColor="transparent" accept=".png">
                              <Upload
                                multiple={false}
                                listType="picture-card"
                                customRequest={({ onSuccess }) =>
                                  onSuccess("ok")
                                }
                                fileList={fileList}
                                showUploadList={true}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                maxCount={1}
                              >
                                {fileList.length === 1 ? null : "Upload"}
                              </Upload>
                            </ImgCrop>
                          </Form.Item>
                          {/* <ImageUploadAndPreview
                              form={form}
                              fileList={fileList}
                              previewOpen={previewOpen}
                              previewTitle={previewTitle}
                              previewImage={previewImage}
                              setPreviewOpen={setPreviewOpen}
                              setPreviewTitle={setPreviewTitle}
                              setPreviewImage={setPreviewImage}
                              setFileList={setFileList}
                            /> */}
                          {/* <img src={service_01} alt="" /> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="sub-title">
                      <h6>Service Information</h6>
                    </div>
                    <div className="form-group">
                      <label>Service Title</label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Name is required!",
                          },
                        ]}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Services Name"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Services Details</label>
                      <div id="editor">
                        <Form.Item
                          name="description"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Description is required!",
                            },
                          ]}
                        >
                          <TextEditor />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <div className="sub-title">
                      <h6>Status</h6>
                    </div>
                    <div className="status-toggle float-sm-start mb-3">
                      <Form.Item
                        name="active"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: "Status is required!",
                          },
                        ]}
                      >
                        <span>
                          <input
                            checked={active ?? false}
                            type="checkbox"
                            id="status_1"
                            className="check"
                            onChange={(e) => {
                              form.setFieldsValue({
                                active: e.target.checked,
                              });
                              form.validateFields(["active"]);
                            }}
                          />
                          <label
                            htmlFor="status_1"
                            className="checktoggle"
                          ></label>
                        </span>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="sub-title">
                      <h6>From To Where Postal Code Required?</h6>
                    </div>
                    <div className="status-toggle float-sm-start mb-3">
                      <Form.Item name="isToAndFrom" valuePropName="checked">
                        <span>
                          <input
                            checked={isToAndFrom ?? false}
                            type="checkbox"
                            id="isToAndFrom"
                            className="check"
                            onChange={(e) => {
                              form.setFieldsValue({
                                isToAndFrom: e.target.checked,
                              });
                              form.validateFields(["isToAndFrom"]);
                            }}
                          />
                          <label
                            htmlFor="isToAndFrom"
                            className="checktoggle"
                          ></label>
                        </span>
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="status-toggle float-sm-end mb-3">
                      <Form.Item
                        name="active"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: "Status is required!",
                          },
                        ]}
                      >
                        <span>
                          <input
                            checked={active ?? false}
                            type="checkbox"
                            id="status_1"
                            className="check"
                            onChange={(e) => {
                              form.setFieldsValue({
                                active: e.target.checked,
                              });
                              form.validateFields(["active"]);
                            }}
                          />
                          <label
                            htmlFor="status_1"
                            className="checktoggle"
                          ></label>
                        </span>
                      </Form.Item>
                    </div>
                  </div> */}
                </div>

                <div className="field-btns">
                  <Link to="/services" className="btn btn-cancel me-3">
                    Cancel
                  </Link>
                  <button
                    className="btn btn-primary "
                    type="submit"
                    // onClick={nextTab3}
                  >
                    {id ? "Save Changes" : "Create"}
                    <i className="fe fe-arrow-right-circle" />
                  </button>
                </div>
              </fieldset>
            </Form>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddService;
