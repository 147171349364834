import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {},
};

const BlogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogs: (state, { payload }) => {
      state.list = payload.reduce(
        (prevBlogs, blog) => ({
          ...prevBlogs,
          [blog.id + "_blogs"]: blog,
        }),
        {}
      );
    },
    setBlog: (state, { payload }) => {
      if (payload)
        state.list = { [payload.id + "_blogs"]: payload, ...state.list };
    },
    removeBlog: (state, { payload }) => {
      if (payload && state.list[payload+ "_blogs"]) delete state.list[payload + "_blogs"];
    },
  },
});

export const { setBlogs, setBlog, removeBlog } = BlogSlice.actions;
export default BlogSlice.reducer;

export const selectBlogsObject = (state) => state.blogs.list;

export const selectBlogs = createDraftSafeSelector(
  [selectBlogsObject],
  (list) => Object.values(list ?? {})
);
export const selectBlogById = (id) =>
  createDraftSafeSelector([selectBlogsObject], (list) => list[id+'_blogs']);

// {
//   "data": {
//       "id": 11,
//       "textBox": "<p> \"this is a text box\" </p>",
//       "thumbnail": "photo_thumbnail_1698856138629.jpg",
//       "thumbnailAlt": "this is a string",
//       "bannerImage": "photo_bannerimage_1698856138644.jpg",
//       "bannerImageAlt": "this is a string",
//       "metaTags": "[\"string1\", \"string2\", \"string3\" ]",
//       "updatedAt": "2023-11-01T16:28:59.094Z",
//       "createdAt": "2023-11-01T16:28:59.094Z"
//   },
//   "message": "Created successfully.",
//   "success": true
// }
