import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

export const useLoaderDispatch = (loadData, isLoading= true) => {
  const [loading, setLoading] = useState(isLoading);
  const dispatch = useDispatch();

  const fetchData = useCallback(async (params) => {
    if (loadData) {
      setLoading(true);
      const res = await dispatch(loadData(params)).unwrap();
      setLoading(false);
      return res;
    }
    return { success: false };
  }, [dispatch, loadData]);

  return [loading, fetchData];
};
