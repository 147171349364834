import Delete from "assets/images/icons/delete.svg";
import edit from "assets/images/icons/edit.svg";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { Table } from "antd";
import { Link } from "react-router-dom";
// import providerOfferslist from "assets/json/providerOffers";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBlogs } from "store/blogs/blogsSlice";
import { asyncDeleteBlog, asyncGetAllBlogs } from "store/blogs/blogsThunk";
import { formatDate, getColumnSearchProps } from "utils";
import blog from "../../assets/images/provider.png";
import { BASE_URL_IMAGE } from "services/axios";
// import { getServicesByRoute } from "store/service/serviceSlice";
// import { useLoaderDispatch } from "hooks/useLoaderDispatch";
// import AskModal from "components/AskModal";

const AdminAllBlog = () => {
  const dispatch = useDispatch();
  const [blogId, setBlogId] = useState(null);
  const blogs = useSelector(selectBlogs);

  const loading = useSelector((state) => state.common.loading);

  useEffect(() => {
    dispatch(asyncGetAllBlogs());
  }, [dispatch]);

  const handleDeleteBlog = async () => {
    let res = await dispatch(asyncDeleteBlog(blogId)).unwrap();
    if (res.success) {
      setBlogId(null);
    }
  };

  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Blog",
      dataIndex: "title",
      render: (title, record) => (
        <div className="table-imgname">
          
          <img src={BASE_URL_IMAGE + record.thumbnail} className="me-2" alt="img" />
          <span>{title}</span>
          {/* <span>{name}</span> */}
        </div>
      ),
      ...getColumnSearchProps(searchInput, "title"),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (updatedAt) => <span>{formatDate(updatedAt)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="table-actions d-flex">
          <Link className="delete-table me-2" to={`${record?.id}`}>
            <img src={edit} alt="svg" />
          </Link>
          <Link
            className="delete-table me-2"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-item"
            onClick={(e) => {
              e.preventDefault();
              setBlogId(record?.id);
            }}
          >
            <img src={Delete} alt="svg" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit mb-0">
            <h5>All Blogs</h5>
            <div className="list-btn">
              <ul>
                <li>
                  <Link className="btn btn-primary" to="/blogs/add">
                    <i className="fa fa-plus me-2" />
                    Add Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12 p-0 p-md-auto ">
              <div className="table-resposnive table-div pt-2 pt-md-3">
                <div className="table datatable">
                  <Table
                    scroll={{
                      x: 670,
                    }}
                    columns={columns}
                    dataSource={blogs}
                    loading={loading}
                    rowKey={(record) => record.id}
                    showSizeChanger={true}
                    pagination={{
                      total: blogs?.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AskModal
        id="delete-item"
        status="Delete"
        title={"Service"}
        onClick={handleDeleteBlog}
        onCancel={() => setServiceId(null)}
      /> */}
      {/* Delete */}
      <div
        className="modal fade"
        id="delete-item"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Blog
              </h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fe fe-x">
                  <FeatherIcon icon={"x"} />
                </i>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="del-modal">
                <p>Are you sure want to Delete?</p>
              </div>
            </div>
            <div className="modal-footer pt-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setBlogId(null)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleDeleteBlog}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete */}
    </>
  );
};
export default AdminAllBlog;
