import user_01 from "assets/images/customer/user-01.jpg";
import user_03 from "assets/images/customer/user-03.jpg";
import user_09 from "assets/images/customer/user-09.jpg";

export default {
  AdminUsers: [
    {
      id: 1,
      dates: "30 Sep 2022",
      customer: user_01,
      customerName: "Admin",
      pending: "Active",
      role: "Administrator",
      bg: "badge-active",
      email: "admin@gmail.com",
      icon: "Edit",
      seen: "2 mins ago",
      ph: "+1 843-443-3282",
    },
    {
      id: 2,
      dates: "27 Sep 2022",
      customer: user_03,
      customerName: "John Smith",
      pending: "Active",
      seen: "10 mins ago",
      role: "Manager",
      bg: "badge-active",
      email: "JohnSmith@gmail.com",
      icon: "Edit",

      ph: "1 917-409-0861",
    },
    {
      id: 3,

      dates: "25 Sep 2022",
      customer: user_09,
      customerName: "Robert",
      pending: "Active",
      role: "Accountant",
      bg: "badge-active",
      email: "Robert@gmail.com",
      content: "Edit",
      icon: "Edit",
      seen: "Online",
      ph: "+1 956-623-2880",
      class: "online-path",
      class1: "online-set",
    },
  ],
};
