import { useCallback, useEffect } from "react";

import arrow_right from "assets/images/icons/arrow-right.svg";
// import arrow_up_svg from "assets/images/icons/arrow-up.svg";
// import money from "assets/images/icons/money.svg";
// import service from "assets/images/icons/service.svg";
import user_circle from "assets/images/icons/user-circle.svg";
import user_svg from "assets/images/icons/user.svg";

// import graph_1 from "assets/images/graph/graph1.png";
// import graph_2 from "assets/images/graph/graph2.png";
// import graph_3 from "assets/images/graph/graph3.png";
// import graph_4 from "assets/images/graph/graph4.png";

// import au from "assets/images/flags/au.png";
// import ca from "assets/images/flags/ca.png";
// import ins from "assets/images/flags/in.png";
// import us from "assets/images/flags/us.png";

import { Table } from "antd";
// import AdminDashboard from "assets/json/dashboard";
// import AdminDashboardProvider from "assets/json/dashboardProvider";
// import AdminRecentBooking from "assets/json/dashboardRecentBooking";
// import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
// import { Bar } from "react-chartjs-2";
// import WorldMap from "react-world-map";
// import { Feather } from "feather-icons-react/build/IconComponents";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { asyncGetAllBookings } from "store/booking/bookingThunk";
import { asyncGetAllCustomers } from "store/customer/customerThunk";
import { asyncGetAllServices } from "store/service/serviceThunk";
import { asyncGetAllVendors } from "store/vendor/vendorThunk";
// import { asyncGetAllTransactions } from "store/payment/paymentThunk";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";
import { getRecentBookings } from "store/booking/bookingSlice";
import { getCustomersCount } from "store/customer/customerSlice";
import {
  asyncGetAllPayments,
  asyncGetAllTransactions,
} from "store/payment/paymentThunk";
import {
  getFeaturedServices,
  getServicesCount,
} from "store/service/serviceSlice";
import {
  bookingStatuses,
  formatDate,
  serviceStatuses,
  statusBadges,
} from "utils";
import { BASE_URL_IMAGE } from "services/axios";
import {
  getRecentTransactions,
  getTotalRevenue,
} from "store/payment/paymentSlice";
import { getVendorsCount } from "store/vendor/vendorSlice";

const AdminDasBoard = () => {
  const [customerLoader, fetchCustomers] =
    useLoaderDispatch(asyncGetAllCustomers);
  const [serviceLoader, fetchServices] = useLoaderDispatch(asyncGetAllServices);
  const [vendorLoader, fetchVendors] = useLoaderDispatch(asyncGetAllVendors);
  const [bookingLoader, fetchBookings] = useLoaderDispatch(asyncGetAllBookings);
  const [paymentLoader, fetchPayments] = useLoaderDispatch(asyncGetAllPayments);
  const [transactionLoader, fetchTransactions] = useLoaderDispatch(
    asyncGetAllTransactions
  );

  // const data = AdminDashboard.AdminDashboard;
  // const datas = AdminDashboardProvider.AdminDashboardProvider;
  // const datas3 = AdminRecentBooking.AdminRecentBooking;

  let customersCount = useSelector(getCustomersCount);
  let vendorsCount = useSelector(getVendorsCount);
  let servicesCount = useSelector(getServicesCount);
  let totalRevenue = useSelector(getTotalRevenue);

  let featuredServices = useSelector(getFeaturedServices);
  let recentBookings = useSelector(getRecentBookings);
  let recentTransactions = useSelector(getRecentTransactions);

  useEffect(() => {
    fetchCustomers();
    fetchServices();
    fetchVendors();
    fetchBookings();
    fetchPayments();
    fetchTransactions();
  }, [
    fetchCustomers,
    fetchServices,
    fetchVendors,
    fetchBookings,
    fetchPayments,
    fetchTransactions,
  ]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Service",
      dataIndex: "name",
      render: (name, record) => (
        <div className="table-imgname">
          <img src={BASE_URL_IMAGE + record.image} className="me-2" alt="img" />
          <span>{name}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => (
        <h6 className={active ? "badge-active" : "badge-inactive"}>
          {active ? "Active" : "In Active"}
        </h6>
      ),
    },
  ];

  const transactionColumns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Booking Id",
      dataIndex: "bookingId",
      render: (bookingId) => <span>{bookingId}</span>,
      sorter: (a, b) => a.bookingId - b.bookingId,
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      render: (type) => {
        let percentage = type === "initial" ? "20%" : "80%";
        return (
          <span>
            {type?.toUpperCase()} ({percentage})
          </span>
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "paidAmount",
      render: (paidAmount) => <span>${paidAmount}</span>,
    },
  ];

  const bookingColumns = [
    {
      title: "#",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Customer",
      dataIndex: "createdAt",
      render: (_, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Services",
      dataIndex: "bookingServices",
      render: (record) => {
        return record?.map((service, index) => {
          let status = service?.disputeReason ? 18 : service?.status;
          let statusBadge = statusBadges[status];

          return (
            <div
              key={index}
              className="d-flex w-75 justify-content-between align-items-center mt-1 mb-1"
            >
              <span className="me-1">{service?.service?.name} : </span>
              <h6 className={statusBadge}>{serviceStatuses[status]}</h6>
            </div>
          );
        });
      },
    },
    {
      title: "Booking Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    {
      title: "Quotes",
      dataIndex: "bookingServices",
      render: (record) => {
        return record?.map((service, index) => {
          return (
            <div
              key={index}
              className="d-flex w-75 justify-content-between mt-1 mb-1"
            >
              <span className="me-1">{service?.service?.name} : </span>
              <h6 className="badge-active">
                ${service?.assignedQuote?.quoteAmount ?? "0"}
              </h6>
            </div>
          );
        });
      },
    },
    {
      title: "Booking Status",
      dataIndex: "status",
      render: (status, record) => {
        status = +status === 6 && !record?.isCompleted ? 5 : status;
        return (
          <h6 className={statusBadges[status]}>
            {bookingStatuses?.[status]?.value}
          </h6>
        );
      },
      // sorter: (a, b) => a.pending.length - b.pending.length,
    },
  ];

  // const chartData = {
  //   // chart: {
  //   //   id: "apexchart-example",
  //   //   // foreColor: theme.palette.primary.main,
  //   //   type:"line"
  //   // },
  //   colors: ["#4169E1"],
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: "55%",
  //       endingShape: "rounded",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     show: true,
  //     width: 2,
  //     colors: ["transparent"],
  //   },
  //   xaxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //     ],
  //   },
  //   yaxis: {
  //     title: {
  //       text: "$ (thousands)",
  //     },
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   legend: {
  //     // position: '',
  //     width: 400,
  //     // position: 'top',
  //   },
  //   series: [
  //     {
  //       name: "Received",
  //       type: "column",
  //       data: [70, 150, 80, 180, 150, 175, 201, 60, 200, 120, 190, 160, 50],
  //     },
  //     {
  //       name: "Revenue",
  //       data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  //     },
  //     {
  //       name: "Free Cash Flow",
  //       data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  //     },
  //   ],
  //   chart: {
  //     type: "bar",
  //     height: 350,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  // };

  // const Revenue = {
  //   series: [
  //     {
  //       name: "series1",
  //       data: [11, 32, 45, 32, 34, 52, 41],
  //       colors: [" #4169E1"],
  //     },
  //     {
  //       name: "series2",
  //       colors: [" #4169E1"],
  //       data: [31, 40, 28, 51, 42, 109, 100],
  //     },
  //   ],
  //   chart: {
  //     height: 350,
  //     type: "area",
  //   },
  //   fill: {
  //     colors: [" #4169E1", "#4169E1"],
  //     type: "gradient",
  //     gradient: {
  //       shade: "dark",
  //       type: "horizontal",
  //       shadeIntensity: 0.1,
  //       gradientToColors: undefined,
  //       inverseColors: true,
  //       opacityFrom: 0.5,
  //       opacityTo: 0.5,
  //       stops: [0, 50, 100],
  //       colorStops: [],
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     width: 1,
  //     curve: "smooth",
  //     dashArray: [0, 8, 5],
  //     opacityFrom: 0.5,
  //     opacityTo: 0.5,
  //     colors: [" #4169E1"],
  //   },
  //   xaxis: {
  //     type: "month",
  //     categories: ["jan", "feb", "march", "april", "may", "june", "july"],
  //   },
  //   tooltip: {},
  // };

  // const Income = {
  //   series: [
  //     {
  //       name: "series1",
  //       data: [31, 40, 28, 51, 42, 109, 100],
  //     },
  //   ],
  //   chart: {
  //     height: 350,
  //     type: "area",
  //   },
  //   fill: {
  //     colors: [" #4169E1"],
  //     type: "gradient",
  //     gradient: {
  //       type: "horizontal",
  //       shadeIntensity: 0.1,
  //       gradientToColors: undefined,
  //       inverseColors: true,
  //       opacityFrom: 0.5,
  //       opacityTo: 0.5,
  //       stops: [0, 50, 100],
  //       colorStops: [],
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //   },
  //   xaxis: {
  //     type: "Month",
  //     categories: ["Jan", "Feb", "March", "April", "May", "Jun", "July"],
  //   },
  //   tooltip: {},
  // };

  // const book = {
  //   series: [10, 45, 45],
  //   chart: {
  //     width: 200,
  //     type: "pie",
  //   },
  //   labels: ["Team A", "Team B", "Team C"],
  //   color: ["#1BA345", "#0081FF", " #FEC001"],
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         chart: {
  //           width: 200,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex widget-path widget-service">
              <div className="card">
                <div className="card-body">
                  <div className="home-user">
                    <div className="home-userhead">
                      <div className="home-usercount">
                        <span>
                          <img src={user_svg} alt="img" />
                        </span>
                        <h6>Customers</h6>
                      </div>
                      <div className="home-useraction">
                        <span className="counters" data-count={30}>
                          {customerLoader ? "Loading..." : customersCount}
                        </span>
                        {/* <Link
                          className="delete-table bg-white"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          data-popper-placement="bottom-end"
                        >
                          <li>
                            <Link to="user-list.html" className="dropdown-item">
                              {" "}
                              View
                            </Link>
                          </li>
                          <li>
                            <Link to="edit-user.html" className="dropdown-item">
                              {" "}
                              Edit
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* <div className="home-usercontent">
                      <div className="home-usercontents">
                        <div className="home-usercontentcount">
                          <img src={arrow_up_svg} alt="img" className="me-2" />
                          <span className="counters" data-count={30}>
                            30
                          </span>
                        </div>
                        <h5> Current Month</h5>
                      </div>
                      <div className="homegraph">
                        <img src={graph_1} alt="img" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex widget-path widget-service">
              <div className="card">
                <div className="card-body">
                  <div className="home-user home-provider">
                    <div className="home-userhead">
                      <div className="home-usercount">
                        <span>
                          <img src={user_circle} alt="img" />
                        </span>
                        <h6>Vendors</h6>
                      </div>
                      <div className="home-useraction">
                        <span className="counters" data-count={25}>
                          {vendorLoader ? "Loading..." : vendorsCount}
                        </span>
                        {/* <Link
                          className="delete-table bg-white"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          data-popper-placement="bottom-end"
                        >
                          <li>
                            <Link
                              to="user-providers.html"
                              className="dropdown-item"
                            >
                              {" "}
                              View
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="edit-provider.html"
                              className="dropdown-item"
                            >
                              {" "}
                              Edit
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* <div className="home-usercontent">
                      <div className="home-usercontents">
                        <div className="home-usercontentcount">
                          <img src={arrow_up_svg} alt="img" className="me-2" />
                          <span className="counters" data-count={25}>
                            25
                          </span>
                        </div>
                        <h5> Current Month</h5>
                      </div>
                      <div className="homegraph">
                        <img src={graph_2} alt="img" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex widget-path widget-service">
              <div className="card">
                <div className="card-body">
                  <div className="home-user home-service">
                    <div className="home-userhead">
                      <div className="home-usercount">
                        <span>
                          <FeatherIcon icon={"briefcase"} />
                        </span>
                        <h6>Services</h6>
                      </div>
                      <div className="home-useraction">
                        <span className="counters" data-count={30}>
                          {serviceLoader ? "Loading..." : servicesCount}
                        </span>
                        {/* <Link
                          className="delete-table bg-white"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          data-popper-placement="bottom-end"
                        >
                          <li>
                            <Link to="services.html" className="dropdown-item">
                              {" "}
                              View
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="edit-service.html"
                              className="dropdown-item"
                            >
                              {" "}
                              Edit
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* <div className="home-usercontent">
                      <div className="home-usercontents">
                        <div className="home-usercontentcount">
                          <img src={arrow_up_svg} alt="img" className="me-2" />
                          <span className="counters" data-count={18}>
                            18
                          </span>
                        </div>
                        <h5> Current Month</h5>
                      </div>
                      <div className="homegraph">
                        <img src={graph_3} alt="img" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex widget-path widget-service">
              <div className="card">
                <div className="card-body">
                  <div className="home-user home-subscription">
                    <div className="home-userhead">
                      <div className="home-usercount">
                        <span>
                          {/* <img src={money} alt="img" /> */}
                          <FeatherIcon icon={"dollar-sign"} />
                        </span>
                        <h6>Revenue</h6>
                      </div>
                      <div className="home-useraction">
                        <span className="counters" data-count={20}>
                          {paymentLoader ? "Loading..." : "$" + totalRevenue}
                        </span>
                        {/* <Link
                          className="delete-table bg-white"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          data-popper-placement="bottom-end"
                        >
                          <li>
                            <Link
                              to="membership.html"
                              className="dropdown-item"
                            >
                              {" "}
                              View
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="dropdown-item">
                              {" "}
                              Edit
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* <div className="home-usercontent">
                      <div className="home-usercontents">
                        <div className="home-usercontentcount">
                          <img src={arrow_up_svg} alt="img" className="me-2" />
                          <span className="counters" data-count={650}>
                            $650
                          </span>
                        </div>
                        <h5> Current Month</h5>
                      </div>
                      <div className="homegraph">
                        <img src={graph_4} alt="img" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* graph Row */}

          {/* <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 d-flex  widget-path">
                <div className="card">
                  <div className="card-body">
                    <div className="home-user">
                      <div className="home-head-user">
                        <h2>Revenue</h2>
                        <div className="home-select">
                          <div className="dropdown">
                            <button
                              className="btn btn-action btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Monthly
                            </button>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Weekly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Monthly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Yearly
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="delete-table bg-white"
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              />
                            </Link>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="chartgraph">
                        <div id="chart-view">
                          <ReactApexChart
                            options={Revenue}
                            series={Revenue.series}
                            type="area"
                            height={350}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 d-flex  widget-path">
                <div className="card">
                  <div className="card-body">
                    <div className="home-user">
                      <div className="home-head-user">
                        <h2>Booking Summary</h2>
                        <div className="home-select">
                          <div className="dropdown">
                            <button
                              className="btn btn-action btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Monthly
                            </button>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Weekly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Monthly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Yearly
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="delete-table bg-white"
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              />
                            </Link>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="chartgraph">
                        <div id="chart-booking">
                          <ReactApexChart
                            options={chartData}
                            series={chartData.series}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12 col-sm-6 col-12 d-flex  widget-path">
                <div className="card">
                  <div className="card-body">
                    <div className="home-user">
                      <div className="home-head-user">
                        <h2>Income</h2>
                        <div className="home-select">
                          <div className="dropdown">
                            <button
                              className="btn btn-action btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Monthly
                            </button>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Weekly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Monthly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Yearly
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="delete-table bg-white"
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              />
                            </Link>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="chartgraph">
                        <div id="chart-incomes">
                          <ReactApexChart
                            options={Income}
                            series={Income.series}
                            type="area"
                            height={350}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          {/* graph Row */}
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-sm-12 d-flex widget-path">
              <div className="card">
                <div className="card-body">
                  <div className="home-user">
                    <div className="home-head-user home-graph-header">
                      <h2>Featured Services</h2>
                      <Link to="services" className="btn btn-viewall">
                        View All
                        <img src={arrow_right} className="ms-2" alt="img" />
                      </Link>
                    </div>
                    <div className="table-responsive datatable-nofooter">
                      <div className="table datatable">
                        <Table
                          loading={serviceLoader}
                          columns={columns}
                          dataSource={featuredServices}
                          rowKey={(record) => record.id}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 d-flex widget-path">
              <div className="card">
                <div className="card-body">
                  <div className="home-user">
                    <div className="home-head-user home-graph-header">
                      <h2>Recent Transactions</h2>
                      <Link to="earnings" className="btn btn-viewall">
                        View All
                        <img src={arrow_right} className="ms-2" alt="img" />
                      </Link>
                    </div>
                    <div className="table-responsive datatable-nofooter">
                      <div className="table datatable ">
                        <Table
                          loading={transactionLoader}
                          columns={transactionColumns}
                          dataSource={recentTransactions}
                          rowKey={(record) => record.id}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Global and pie chat */}
          {/* <div className="row">
              <div className="col-xl-8 col-lg-12 col-sm-12 d-flex widget-path">
                <div className="card">
                  <div className="card-body">
                    <div className="home-user">
                      <div className="home-head-user home-graph-header">
                        <h2>Recent Transactions</h2>

                        <div className="home-select">
                          <div className="dropdown">
                            <button
                              className="btn btn-action btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Monthly
                            </button>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Weekly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Monthly
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  Yearly
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="delete-table bg-white"
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              />
                            </Link>
                            <ul
                              className="dropdown-menu"
                              data-popper-placement="bottom-end"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  {" "}
                                  Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="chartgraph">
                        <div className="row align-items-center">
                          <div className="col-lg-7">
                            <div id="world_map" style={{ height: "150px" }}>
                              <WorldMap selected={selected} onSelect={onSelect} />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="bookingmap">
                              <ul>
                                <li>
                                  <span>
                                    <img src={us} alt="img" className="me-2" />
                                    United State
                                  </span>
                                  <h6>60%</h6>
                                </li>
                                <li>
                                  <span>
                                    <img src={ins} alt="img" className="me-2" />
                                    India
                                  </span>
                                  <h6>80%</h6>
                                </li>
                                <li>
                                  <span>
                                    <img src={ca} alt="img" className="me-2" />
                                    Canada
                                  </span>
                                  <h6>50%</h6>
                                </li>
                                <li>
                                  <span>
                                    <img src={au} alt="img" className="me-2" />
                                    Australia
                                  </span>
                                  <h6>75%</h6>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-sm-12 d-flex widget-path">
                <div className="card">
                  <div className="card-body">
                    <div className="home-user">
                      <div className="home-head-user home-graph-header">
                        <h2>Booking Statistics</h2>
                        <Link to="booking.html" className="btn btn-viewall">
                          View All
                          <img src={arrow_right} className="ms-2" alt="img" />
                        </Link>
                      </div>
                      <div className="chartgraph">
                        <div className="row align-items-center">
                          <div className="col-lg-7 col-sm-6">
                            <div id="chart-bar">
                              <ReactApexChart
                                options={book}
                                series={book.series}
                                type="pie"
                                height={350}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-sm-6">
                            <div className="bookingstatus">
                              <ul>
                                <li>
                                  <span />
                                  <h6>Completed</h6>
                                </li>
                                <li className="process-status">
                                  <span />
                                  <h6>Process</h6>
                                </li>
                                <li className="process-pending">
                                  <span />
                                  <h6>Pending</h6>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* Global and pie chat */}

          <div className="row">
            <div className="col-lg-12 widget-path">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="home-user">
                    <div className="home-head-user home-graph-header">
                      <h2>Recent Booking</h2>
                      <Link to="/bookings" className="btn btn-viewall">
                        View All
                        <img src={arrow_right} className="ms-2" alt="img" />
                      </Link>
                    </div>
                    <div className="table-responsive datatable-nofooter">
                      <div className="table datatable">
                        <Table
                          loading={bookingLoader}
                          columns={bookingColumns}
                          dataSource={recentBookings}
                          rowKey={(record) => record.id}
                          showSizeChanger={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDasBoard;
