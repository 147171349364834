// import Lottie from "react-lottie";
import LoaderGif from "../../assets/images/pageLoader.gif";

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

function PageLoader() {
  return (
    <div
      className="page-wrapper min-vh-100 page-settings position-fixed d-flex justify-centent-center align-items-center start-0 end-0 p-0"
      style={{
        background: "#ffffff8c",
        zIndex: 99999,
      }}
    >
      <img src={LoaderGif} alt="loader" width={250} height={250} className="m-auto" />
      {/* <Lottie options={defaultOptions} height={250} width={250} /> */}
    </div>
  );
}

export default PageLoader;
