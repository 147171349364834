import { Button, Drawer, Form, Modal, Space } from "antd";
import React, { useEffect } from "react";
import user_01 from "assets/images/user1.png";
import { BASE_URL_IMAGE } from "services/axios";

export const QuotesDrawer = ({
  bookingService,
  open,
  handleSave,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const [isError, setIsError] = React.useState(false);

  return (
    <Modal
      open={open}
      zIndex={9999}
      width={"40vw"}
      centered
      footer={
        <Space>
          <button
            type="button"
            role="button"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            role="button"
            className="btn btn-primary"
            onClick={() => {
              form.submit();
            }}
          >
            Select
          </button>
        </Space>
      }
      destroyOnClose
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
        setIsError(false);
      }}
    >
      <Form
        form={form}
        onFinish={(values) => {
          if (isError) {
            setIsError(false);
          }
          handleSave(values);
        }}
        onFinishFailed={() => {
          setIsError(true);
        }}
        initialValues={{
          quoteId: "",
        }}
      >
        <div className=" d-flex align-items-center">
          <h2 className="text-xl font-600 mt-3 mb-3 me-3  border-bottom-1">
            Quotation List
          </h2>
          {isError && (
            <span className="text-danger fs-6">(Please Select Quote)</span>
          )}
        </div>
        <div
          className="row overflow-auto justify-content-start flex-wrap"
          style={{
            height: "50vh",
            marginInline: "0",
          }}
        >
          {bookingService?.quotes?.map((quote) => {
            if (!quote?.quoteAmount) return null;
            return (
              <div
                key={quote.id}
                className="col-12 col-sm-6  quote-card border-0"
              >
                <div className="card-body border border-1 rounded p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="profile-upload mb-0">
                      <div className="profile-upload-img">
                        <img
                          src={
                            quote?.vendor?.image
                              ? BASE_URL_IMAGE + quote?.vendor?.image
                              : user_01
                          }
                          alt="img"
                          id="blah"
                        />
                      </div>
                      <h5 className="card-title mb-0">{quote?.vendor?.name}</h5>
                    </div>
                    <div>
                      <Form.Item
                        noStyle
                        name="quoteId"
                        rules={[
                          {
                            required: true,
                            message: "Please select quote",
                          },
                        ]}
                      >
                        <input
                          name="quoteId"
                          className="quote form-check-input mt-0 fs-4"
                          type="radio"
                          onChange={(e) => {
                            form.setFieldsValue({
                              quoteId: quote?.id,
                            });
                            setIsError(false);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <p className="card-text mt-2 mb-2">
                    {quote?.quoteDescription ||
                      `No description provided by vendor`}
                  </p>
                  <span className="fs-3 fw-bold mb-0">
                    ${quote?.quoteAmount || 0}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </Modal>
  );
};

export default QuotesDrawer;
