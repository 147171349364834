import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sub_services: {},
  inactive: {},
};

const SubServiceSlice = createSlice({
  name: "subService",
  initialState,
  reducers: {
    setSubServices: (state, { payload }) => {
      state.sub_services = payload.sub_services || {};
      state.inactive = payload.inactive || {};
    },
    setSubService: (state, { payload }) => {
      state[payload.active ? "sub_services" : "inactive"][
        "sub_service_" + payload.id
      ] = payload;
    },
    moveSubServiceToActive: (state, { payload }) => {
      const { id } = payload;
      const subService = state.inactive["sub_service_" + id];
      if (subService) {
        delete state.inactive["sub_service_" + id];
        state.sub_services["sub_service_" + id] = subService;
      }
    },
    removeSubService: (state, { payload }) => {
      delete state[payload.active ? "sub_services" : "inactive"][
        "sub_service_" + payload.id
      ];
    },
  },
});

export const {
  setSubServices,
  setSubService,
  removeSubService,
  moveSubServiceToActive,
} = SubServiceSlice.actions;
export default SubServiceSlice.reducer;
