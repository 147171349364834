import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { asyncAlertError, asyncAlertSuccess } from "../common/commonThunk";
import { setLoading } from "../common/commonSlice";
import { setBookings } from "./bookingSlice";
import { bookingStatuses } from "utils";

export const asyncGetAllBookings = createAsyncThunk(
  "booking/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "bookings/all",
        method: "GET",
        token,
      });

      let dataSource = {};
      let bookings = {};

      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          let status = bookingStatuses?.[curr?.status]?.key;
          bookings["booking_" + curr.id] = curr;

          return {
            ...acc,
            [status]: { ...(acc[status] || {}), ["booking_" + curr.id]: curr },
          };
        }, {});

        dispatch(setBookings({ ...dataSource, bookings }));
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncCreateQuotation = createAsyncThunk(
  "booking/quotation/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/quotes",
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateQuotation = createAsyncThunk(
  "booking/vendor/assign",
  async (quoteId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/vendor-assigned/${quoteId}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateBooking = createAsyncThunk(
  "booking/update",
  async ({ bookingId, amount }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/quotes/${bookingId}`,
        method: "PUT",
        body: { amount },
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncDiscardServiceBooking = createAsyncThunk(
  "booking/service/discard",
  async (bookingServiceId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/discarded/${bookingServiceId}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncResolveServiceBookingDispute = createAsyncThunk(
  "booking/service/dispute/resolve",
  async (bookingServiceId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/resolve-dispute/${bookingServiceId}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
