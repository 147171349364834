// import user_01 from "assets/images/customer/user-01.jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "commoncomponent/Select/Select";
import React, { useCallback, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import edit from "assets/images/icons/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAllServices } from "store/service/serviceThunk";
import {
  asyncCreateSubService,
  asyncGetAllSubServices,
  asyncUpdateSubService,
} from "store/subService/subServiceThunk";
import { Form } from "antd";
import { useMemo } from "react";
// import { tab } from "@testing-library/user-event/dist/tab";
import styles from "./services.module.css";
import { store } from "store";
import { asyncAlertError } from "store/common/commonThunk";

const recordObj = {
  label: "",
  type: "text",
};

const ItemTypes = {
  SINGLE: "single",
  GROUP: "group",
};

const optionsData = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "checkbox", label: "Checkbox" },
  { value: "params", label: "Input Number Group" },
  { value: "radio", label: "Radio" },
];

const DeleteItemBtn = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick} className={styles.removeBtn}>
      Remove
    </button>
  );
};

const AddOptionBtn = ({ title, onClick }) => {
  return (
    <a
      className="link-sets"
      style={{
        fontSize: "14px",
      }}
      onClick={onClick}
    >
      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
      {title}
    </a>
  );
};

const AddParamBtn = ({ title, onClick }) => {
  return (
    <a className="link-sets" onClick={onClick}>
      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
      {title}
    </a>
  );
};

const ParamOption = ({
  formItemName,
  labelValue,
  count,
  updateOption,
  deleteOption,
  type,
}) => {
  return (
    <div className="row align-items-end">
      <div className="col-lg-2">
        <div className="form-group">
          <label>{type === "params" ? "Label" : "Option Name"}</label>
          <Form.Item
            name={formItemName}
            validateFirst={true}
            rules={[
              {
                required: true,
                whitespace: true,
                message: `Option is required!`,
              },
            ]}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Enter Sub Service Name"
              value={labelValue}
              onChange={(e) => updateOption("label", e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      {count > 1 && (
        <a
          className="cursor-pointer mt-2 d-flex align-self-center"
          style={{ width: "max-content" }}
          onClick={deleteOption}
        >
          <FeatherIcon icon="x-circle" className="text-secondary" />
        </a>
      )}
    </div>
  );
};

const SingleParamElement = ({
  formItemLabelName,
  formItemTypeName,
  labelValue,
  typeValue,
  updateItem,
  className = {},
  children,
}) => {
  return (
    <div className={`row align-items-end ${className}`}>
      <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 mt-sm-2">
        <div className="form-group">
          <label>Label</label>
          <Form.Item
            name={formItemLabelName}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: `Label is required!`,
              },
            ]}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Enter Sub Service Name"
              value={labelValue}
              onChange={(e) => updateItem("label", e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 mt-sm-2">
        <div className="form-group">
          <label>Select Input Type</label>
          <Form.Item
            name={formItemTypeName}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: `Type is required!`,
              },
            ]}
          >
            <Select
              options={optionsData}
              value={typeValue}
              onChange={(value) => updateItem("type", value.value)}
            />
          </Form.Item>
        </div>
      </div>
      {children}
    </div>
  );
};

const AddSubService = () => {
  const [form] = Form.useForm();

  const [items, setItems] = useState([]);
  const [groupHeadingIndex, setGroupHeadingIndex] = useState(null);

  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  let [parentRoute, childRoute, routeId] = pathname.split("/").filter((x) => x);

  let services = useSelector((state) => state.service?.services);
  services = useMemo(() => Object.values(services ?? {}), [services]);

  const subService = useSelector((state) => {
    if (routeId) {
      return state.subService?.[childRoute]?.["sub_service_" + id];
    } else if (!routeId) {
      return state.subService?.[parentRoute]?.["sub_service_" + id];
    }
    return {};
  });

  const onFinish = async (values) => {
    if (!items.length) {
      return store.dispatch(
        asyncAlertError("Please add at least one parameter!")
      );
    }

    let subServiceProps = {
      ...(values.name !== subService?.name ? { name: values.name } : {}),
      parentId: values.parentId.value,
      serviceParameters: items,
    };

    let res = {};
    if (id) {
      res = await dispatch(
        asyncUpdateSubService({
          subServiceId: subService?.id,
          body: subServiceProps,
        })
      ).unwrap();
    } else {
      res = await dispatch(asyncCreateSubService(subServiceProps)).unwrap();
    }

    if (res.success) {
      return navigate("/sub_services");
    }
  };

  // Will Add Main Row for Both Single And Group
  const addItem = (type) => {
    let isSingle = type === ItemTypes.SINGLE;

    let item = {
      ...(!isSingle
        ? { groupHeading: "GROUP HEADING", rows: [{ ...recordObj }] }
        : { ...recordObj }),
    };

    setItems([...items, item]);
  };

  // Will Delete Main Row for Both Single And Group
  const deleteItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  // Will Add Child Row for Group
  const addGroupRow = (index) => {
    let updatedItems = [...items];
    updatedItems[index].rows.push({ ...recordObj });
    setItems(updatedItems);
  };

  // Will Delete Child Row for Group
  const deleteGroupRow = (index, rowIndex) => {
    let updatedItems = [...items];
    updatedItems[index].rows = updatedItems[index].rows.filter(
      (_, i) => i !== rowIndex
    );
    setItems(updatedItems);
  };

  // Will Add Option for Single Row
  const addSingleItemOption = (index) => {
    let updatedItems = [...items];

    if (!("options" in updatedItems[index])) {
      updatedItems[index].options = [];
    }

    updatedItems[index].options.push("");

    setItems(updatedItems);
  };

  const deleteSingleItemOption = (index, optionIndex) => {
    let updatedItems = [...items];
    updatedItems[index].options = updatedItems[index].options.filter(
      (_, i) => i !== optionIndex
    );
    setItems(updatedItems);
  };

  const deleteGroupItemOption = (index, rowIndex, optionIndex) => {
    let updatedItems = [...items];
    updatedItems[index].rows[rowIndex].options = updatedItems[index].rows[
      rowIndex
    ].options.filter((_, i) => i !== optionIndex);
    setItems(updatedItems);
  };

  // Will Add Option for Group Row
  const addGroupItemOption = (parentIndex, rowIndex) => {
    let updatedItems = [...items];

    if (!("options" in updatedItems[parentIndex].rows[rowIndex])) {
      updatedItems[parentIndex].rows[rowIndex].options = [];
    }

    updatedItems[parentIndex].rows[rowIndex].options.push("");

    setItems(updatedItems);
  };

  const updateSingleItemKeyValue = (index, key, value) => {
    let updatedItems = [...items];

    if (key == "type") {
      let isSelect =
        key === "type" &&
        (value === "checkbox" || value === "radio" || value === "params");

      if (!isSelect) {
        delete updatedItems[index].options;
      }

      if (isSelect) {
        updatedItems[index].options = [""];
      }
    }

    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  const updateGroupItemKeyValue = (index, rowIndex, key, value) => {
    let updatedItems = [...items];

    if (key == "type") {
      let isSelect =
        key === "type" &&
        (value === "checkbox" || value === "radio" || value === "params");

      if (!isSelect) {
        delete updatedItems[index].rows[rowIndex].options;
      }

      if (isSelect) {
        updatedItems[index].rows[rowIndex].options = [""];
      }
    }

    updatedItems[index].rows[rowIndex][key] = value;
    setItems(updatedItems);
  };

  const updateItemOptionKeyValue = (index, optionIndex, key, value) => {
    let updatedItems = [...items];
    updatedItems[index].options[optionIndex] = value;
    setItems(updatedItems);
  };

  const updateGroupItemOptionKeyValue = (
    index,
    rowIndex,
    optionIndex,
    key,
    value
  ) => {
    let updatedItems = [...items];
    updatedItems[index].rows[rowIndex].options[optionIndex] = value;
    setItems(updatedItems);
  };

  const updateGroupHeading = (index, key, value) => {
    let updatedItems = [...items];
    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  let existingItemsList = useMemo(() => {
    return subService?.parameters?.reduce((acc, curr, index) => {
      let { groupHeading, options, ...currRow } = curr;
      let key = groupHeading ?? index;

      let row = {
        ...currRow,
        ...(options?.length ? { options: JSON.parse(options) } : {}),
      };

      return {
        ...acc,
        [key]: {
          groupHeading: isNaN(key) ? key : null,
          ...(groupHeading
            ? {
                rows: [
                  ...(acc[groupHeading]?.rows?.length > 0
                    ? [...acc[groupHeading].rows, row]
                    : [row]),
                ],
              }
            : row),
        },
      };
    }, {});
  }, [id]);

  let dataToProcess = useMemo(() => {
    return items.length > 0 ? items : Object.values(existingItemsList ?? {});
  }, [items, existingItemsList]);

  useEffect(() => {
    if (form) {
      dataToProcess.forEach((item, index) => {
        if (item?.rows?.length) {
          item.rows.forEach((row, rowIndex) => {
            form.setFieldsValue({
              [`label_${index}_row_${rowIndex}`]: row.label,
              [`type_${index}_row_${rowIndex}`]: optionsData.find(
                (option) => option.value === row.type
              ),
            });

            row?.options?.forEach((option, optionIndex) => {
              form.setFieldsValue({
                [`label_${index}_row_${rowIndex}_option_${optionIndex}`]:
                  option,
              });
            });
          });
        } else {
          form.setFieldsValue({
            [`label_${index}`]: item.label,
            [`type_${index}`]: optionsData.find(
              (option) => option.value === item.type
            ),
          });

          item?.options?.forEach((option, optionIndex) => {
            form.setFieldsValue({
              [`label_${index}_option_${optionIndex}`]: option,
            });
          });
        }
      });
    }
  }, [dataToProcess]);

  useEffect(() => {
    if (form && id) {
      let parentService = subService?.parentService;

      form.setFieldsValue({
        parentId: {
          label: parentService?.name,
          value: parentService?.id,
        },
        name: subService?.name,
      });
    }

    setItems(!id ? [] : dataToProcess);

    return () => {
      form.resetFields();
    };
  }, [id]);

  const loadData = useCallback(async () => {
    dispatch(asyncGetAllServices());
    dispatch(asyncGetAllSubServices());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Add Sub Services</h5>
        </div>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          onFinishFailed={(errorInfo) => {
            console.log(errorInfo);
          }}
          autoComplete="off"
        >
          <div className="row align-items-end">
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="form-group">
                <label>Main Service</label>
                <Form.Item
                  name="parentId"
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                      message: `Parent Service is required!`,
                    },
                  ]}
                >
                  <Select
                    options={Object.values(services ?? {})?.map((service) => {
                      return { value: service.id, label: service.name };
                    })}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="form-group">
                <label>Sub Service Name</label>
                <Form.Item
                  name="name"
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                      message: `Name is required!`,
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sub Service Name"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {items?.map((item, index) => (
            <div key={"item" + index} className={styles.customBorderStyle}>
              <DeleteItemBtn onClick={() => deleteItem(index)} />
              {!item?.rows?.length ? (
                <SingleParamElement
                  key={"single" + index}
                  formItemLabelName={`label_${index}`}
                  formItemTypeName={`type_${index}`}
                  labelValue={item.label}
                  typeValue={item.type}
                  updateItem={(key, value) =>
                    updateSingleItemKeyValue(index, key, value)
                  }
                >
                  {item?.options?.map((option, optionIndex) => {
                    return (
                      <ParamOption
                        key={optionIndex}
                        type={item.type}
                        formItemName={`label_${index}_option_${optionIndex}`}
                        labelValue={option}
                        option={option}
                        count={item?.options?.length}
                        updateOption={(key, value) =>
                          updateItemOptionKeyValue(
                            index,
                            optionIndex,
                            key,
                            value
                          )
                        }
                        deleteOption={() =>
                          deleteSingleItemOption(index, optionIndex)
                        }
                      />
                    );
                  })}
                  {item?.options?.length > 0 && (
                    <div className="row">
                      <div className="col-lg-2 text-end">
                        <AddOptionBtn
                          title={"Add Parameter Options"}
                          onClick={() => {
                            addSingleItemOption(index);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </SingleParamElement>
              ) : (
                <React.Fragment key={index}>
                  <div className="row align-items-center mb-3">
                    {groupHeadingIndex !== index ? (
                      <h5 style={{ width: "max-content" }} className="mb-0">
                        {item.groupHeading || "Group Heading"}
                      </h5>
                    ) : (
                      <div className="col-2">
                        <Form.Item
                          className="mb-0"
                          name={`heading_${index}`}
                          validateFirst={true}
                          rules={[
                            {
                              required: true,
                              message: `Label is required!`,
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Group Heading"
                            value={item.groupHeading}
                          />
                        </Form.Item>
                      </div>
                    )}

                    {groupHeadingIndex === index ? (
                      <>
                        <a
                          className="cursor-pointer"
                          style={{ width: "max-content" }}
                          onClick={() => {
                            setGroupHeadingIndex(null);
                            let headingValue = form.getFieldValue(
                              `heading_${index}`
                            );

                            updateGroupHeading(
                              index,
                              "groupHeading",
                              headingValue
                            );
                          }}
                        >
                          <FeatherIcon icon="save" className="text-secondary" />
                        </a>
                        <a
                          className="cursor-pointer"
                          style={{ width: "max-content" }}
                          onClick={() => {
                            setGroupHeadingIndex(null);
                            form.resetFields([`heading_${index}`]);
                            form.setFieldValue(
                              `heading_${index}`,
                              item.groupHeading
                            );
                          }}
                        >
                          <FeatherIcon
                            icon="x-circle"
                            className="text-secondary"
                          />
                        </a>
                      </>
                    ) : (
                      <a
                        className="cursor-pointer"
                        style={{ width: "max-content" }}
                        onClick={() => {
                          setGroupHeadingIndex(index);
                        }}
                      >
                        <FeatherIcon icon="edit" className="text-secondary" />
                      </a>
                    )}
                  </div>
                  {item?.rows?.map((row, rowIndex) => {
                    let className =
                      item?.rows?.length > 0 ? styles.customBorderStyle : {};

                    return (
                      <SingleParamElement
                        key={"group" + index + rowIndex}
                        formItemLabelName={`label_${index}_row_${rowIndex}`}
                        formItemTypeName={`type_${index}_row_${rowIndex}`}
                        labelValue={row.label}
                        typeValue={row.type}
                        className={className}
                        updateItem={(key, value) =>
                          updateGroupItemKeyValue(index, rowIndex, key, value)
                        }
                      >
                        {item?.rows?.length > 1 && (
                          <a
                            className="cursor-pointer mt-2 d-flex align-self-center"
                            style={{ width: "max-content" }}
                            onClick={() => deleteGroupRow(index, rowIndex)}
                          >
                            <FeatherIcon
                              icon="x-circle"
                              className="text-secondary"
                            />
                          </a>
                        )}
                        {row?.options?.map((option, optionIndex) => {
                          return (
                            <ParamOption
                              key={optionIndex}
                              type={row.type}
                              formItemName={`label_${index}_row_${rowIndex}_option_${optionIndex}`}
                              labelValue={option}
                              option={option}
                              count={row?.options?.length}
                              updateOption={(key, value) =>
                                updateGroupItemOptionKeyValue(
                                  index,
                                  rowIndex,
                                  optionIndex,
                                  key,
                                  value
                                )
                              }
                              deleteOption={() =>
                                deleteGroupItemOption(
                                  index,
                                  rowIndex,
                                  optionIndex
                                )
                              }
                            />
                          );
                        })}
                        {row?.options?.length > 0 ? (
                          <div className="row">
                            <div className="col-lg-2 text-end">
                              <AddOptionBtn
                                title={"Add Parameter Options"}
                                onClick={() => {
                                  addGroupItemOption(index, rowIndex);
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </SingleParamElement>
                    );
                  })}
                  <AddParamBtn
                    title={"Add Parameter Row"}
                    onClick={() => addGroupRow(index)}
                  />
                </React.Fragment>
              )}
            </div>
          ))}
          {/* BUTTONS */}
          <div className={styles.addBtnsGrid}>
            <AddParamBtn
              title={"Add Parameter"}
              onClick={() => addItem(ItemTypes.SINGLE)}
            />
            OR
            <AddParamBtn
              title={"Add Parameter Group"}
              onClick={() => addItem(ItemTypes.GROUP)}
            />
          </div>
          <div className="field-btns">
            <Link to="/sub_services" className="btn btn-cancel me-3">
              Cancel
            </Link>
            <button className="btn btn-primary " type="submit">
              {id ? "Save Changes" : "Create"}
              <i className="fe fe-arrow-right-circle" />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddSubService;
