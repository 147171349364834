import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

export const dateFormats = {
  "MM/DD/YY": "MM/DD/YY",
  "MM/DD/YY - HHmm": "MM/DD/YY - HHmm",
  "MMMM DD, YYYY - HHmm": "MMMM DD, YYYY - HHmm",
  "MM/DD - HHmm": "MM/DD - HHmm",
  "MM DD, YYYY": "MM DD, YYYY",
  "MMM DD, YYYY": "MMM DD, YYYY",
};

export const formatDate = (date, format = null) => {
  return moment(date)?.format(format ?? dateFormats["MMM DD, YYYY"]);
};

export const bookingStatuses = {
  1: { key: "pending", value: "Waiting for Quote" },
  2: { key: "decision", value: "Decision" },
  // 3: { key: "accepted", value: "Accepted" },
  4: { key: "declined", value: "Declined" },
  5: { key: "inprogress", value: "In Progress" },
  6: { key: "completed", value: "Completed" },
  18: { key: "disputed", value: "Disputed" },
  19: { key: "dicarded", value: "Discarded" },
};

export const serviceStatuses = {
  1: "Waiting for Quote",
  2: "Decision",
  3: "Accepted",
  4: "Decline",
  5: "In Progress",
  6: "Completed",
  18: "Disputed",
  19: "Discarded",
};

export const statusBadges = {
  1: "badge-pending",
  2: "badge-inactive",
  3: "badge-active",
  4: "badge-delete",
  5: "badge-active",
  6: "badge-active",
  18: "badge-delete",
  19: "badge-delete",
};

export const serviceTableTabs = [
  { key: "services", value: "Active Services", path: "/services" },
  {
    key: "inactive",
    value: "In Active Services",
    path: "/services/inactive",
  },
];

export const subServiceTableTabs = [
  { key: "sub_services", value: "Active Sub Services", path: "/sub_services" },
  {
    key: "inactive",
    value: "In Active Sub Services",
    path: "/sub_services/inactive",
  },
];

export const vendorTableTabs = [
  { key: "vendors", value: "Active Vendors", path: "/vendors" },
  { key: "inactive", value: "In Active Vendors", path: "/vendors/inactive" },
];

export const bookingTableTabs = [
  { key: "pending", value: "Waiting for Quote", path: "/bookings/pending" },
  { key: "decision", value: "Decision", path: "/bookings/decision" },
  // { key: "accepted", value: "Accepted", path: "/bookings/accepted" },
  { key: "declined", value: "Declined", path: "/bookings/declined" },
  { key: "inprogress", value: "In Progress", path: "/bookings/inprogress" },
  { key: "completed", value: "Completed", path: "/bookings/completed" },
];

/*
 * @params
 *   dataIndex: string
 *   searchInput: React.RefObject
 */
export const getColumnSearchProps = (searchInput, ...dataIndex) => {
  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    try {
      searchInput.current.input.value = "";
    } catch (e) {
      console.log(e);
    }

    clearFilters();
    confirm();
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.at(-1)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#fe5000" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      let dynamicProperty = record;
      if (dataIndex?.length > 1) {
        dynamicProperty = dataIndex?.slice(0, -1)?.reduce((acc, curr) => {
          return acc ? record[curr] : undefined;
        }, dataIndex);
      }
      return dynamicProperty?.[dataIndex?.at(-1)]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };
};

export const toNumber = (value) => {
  return value ? Number(value) : 0;
};

export const validateDecimalsPrecision = () => ({
  validator(_, value) {
    var t = value?.toString();
    let seperatorIndex = t?.indexOf(".");
    let isValid = t?.slice(t?.indexOf(".") + 1)?.length <= 2 ? true : false;

    if (value <= 0) {
      return Promise.reject(new Error("Value must be greater than 0"));
    }

    if (seperatorIndex === -1) {
      return Promise.resolve();
    } else if (isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Decimal precision must be 2"));
    }
  },
});
