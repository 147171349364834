import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { asyncAlertError, asyncAlertSuccess } from "../common/commonThunk";
import { setLoading } from "../common/commonSlice";
import { setVendors } from "./vendorSlice";

export const asyncGetAllVendors = createAsyncThunk(
  "vendor/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/vendors",
        method: "GET",
        token,
      });

      let dataSource = {};

      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          let status = curr.active ? "vendors" : "inactive";

          // : curr.pending
          // ? "pending"
          return {
            ...acc,
            [status]: { ...(acc[status] || {}), ["vendor_" + curr.id]: curr },
          };
        }, {});

        dispatch(setVendors({ ...dataSource }));
        return { success: true };
      }

      dispatch(setVendors({}));
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncCreateVendor = createAsyncThunk(
  "vendor/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/vendors`,
        method: "POST",
        body,
        token,
        isFormData: true,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Vendor Successfully Created!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateVendor = createAsyncThunk(
  "vendor/update",
  async ({ vendorId, body, isFormData }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/vendors/profile/${vendorId}`,
        method: "PUT",
        body,
        token,
        isFormData: isFormData ?? true,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Vendor Successfully Updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// export const asyncBanVendor = createAsyncThunk(
//   "vendor/ban",
//   async (vendorId, { dispatch, getState }) => {
//     try {
//       dispatch(setLoading(true));

//       const { token } = getState().auth;

//       const res = await callApi({
//         path: `admin/vendors/${vendorId}`,
//         method: "PUT",
//         token,
//       });

//       if (res.success) {
//         dispatch(
//           asyncAlertSuccess(
//             `User Successfully ${res.data.ban ? "Banned" : "UnBanned"}!`
//           )
//         );
//         return { success: true };
//       }

//       dispatch(asyncAlertError(res.message));
//       return { success: false };
//     } catch (error) {
//       dispatch(asyncAlertError(error.message));
//       return { success: false };
//     } finally {
//       dispatch(setLoading(false));
//     }
//   }
// );

export const asyncDeleteVendor = createAsyncThunk(
  "vendor/delete",
  async (vendorId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/vendors/${vendorId}`,
        method: "DELETE",
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Vendor Successfully Deleted!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
