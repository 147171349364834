import { Form } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
// import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { selectToken } from "store/auth/authSlice";
import { asyncLoginUser } from "store/auth/authThunk";

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const [showPass, setShowPass] = useState(false);
  const token = useSelector(selectToken);

  const onFinish = async (values) => {
    dispatch(asyncLoginUser(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (token) {
    let callbackUrl = searchParams.get("callbackUrl");
    if (callbackUrl) {
      return <Navigate to={callbackUrl} />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return (
    <div className="content mt-5 p-1">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h3>Login</h3>
              </div>
              {/* Login Form */}
              <Form
                form={form}
                name="login"
                initialValues={{
                  email: "",
                  password: "",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="log-form">
                  <div className="form-group">
                    <label className="col-form-label">E-mail</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          whitespace: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="example@email.com"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">Password</label>
                    <div className="pass-group">
                      <Form.Item
                        name="password"
                        validateFirst
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input your password!",
                          },
                          {
                            min: 8,
                            message: "Must be 8 Characters at Least",
                          },
                        ]}
                      >
                        <span>
                          <input
                            type={showPass ? "text" : "password"}
                            className="form-control pass-input"
                            placeholder="*************"
                          />
                          <span className="toggle-password feather-eye">
                            <FeatherIcon
                              icon={showPass ? "eye-off" : "eye"}
                              onClick={() => setShowPass((s) => !s)}
                            />
                          </span>
                        </span>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="char-length">
                        <p>Must be 8 Characters at Least</p>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="text-end">
                        <Link to="/forget-password">Forgot password?</Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-6">
                      <Form.Item name="remember" valuePropName="checked">
                        <label className="custom_check">
                          <input type="checkbox" className="rememberme" />
                          <span className="checkmark" />
                          Remember Me
                        </label>
                      </Form.Item>
                    </div>
                    <div className="col-6 text-end">
                      <label className="custom_check">
                        <input
                          type="checkbox"
                          name="loginotp"
                          className="loginotp"
                        />
                        <span className="checkmark" />
                        Login with OTP
                      </label>
                    </div>
                  </div> */}
                </div>
                <button
                  className="btn btn-primary w-100 login-btn"
                  type="button"
                  onClick={() => form.submit()}
                >
                  Login
                </button>
              </Form>
              {/* <p className="no-acc">
                  {`Don't have an account ? `}
                  <Link href="/signup">Register</Link>
                </p> */}
              {/* /Login Form */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
