import { useCallback, useEffect } from "react";

// import logo_small_svg from "assets/images/logo.webp";
// import logo_svg from "assets/images/logo.webp";
import user_jpg from "assets/images/user.jpg";
import adminLogo from "assets/images/logo/FooterLogoR.png";

import provider_01 from "assets/images/provider/provider-01.jpg";
// import provider_02 from "assets/images/provider/provider-02.jpg";
// import provider_03 from "assets/images/provider/provider-03.jpg";
// import provider_04 from "assets/images/provider/provider-04.jpg";
// import provider_05 from "assets/images/provider/provider-05.jpg";
// import provider_06 from "assets/images/provider/provider-06.jpg";
// import provider_07 from "assets/images/provider/provider-07.jpg";

// import us from "assets/images/flags/us.png";

// import de from "assets/images/flags/de.png";
// import es from "assets/images/flags/es.png";
// import fr from "assets/images/flags/fr.png";
// import us1 from "assets/images/flags/us1.png";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { resetAuth } from "store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAllNotifications } from "store/notification/notificationThunk";
import { formatDate } from "utils";

const AdminHeader = () => {
  const user = useSelector((state) => state.auth.user);
  let notifications = useSelector((state) => state.notification.notifications);

  notifications = Object.values(notifications ?? {});

  const Reload = () => {
    window.location.reload(false);
  };

  const dispatch = useDispatch();

  const handlesidebar = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.toggle("menu-opened");
    var rootss = document.getElementById("session");
    rootss.classList.toggle("slide-nav");
  };

  // const onMenuClik = () => {
  //   props.onMenuClick();
  // };

  // const pathname = window.location.pathname;
  const exclusionArray = ["/signin"];

  const loadData = useCallback(async () => {
    await dispatch(asyncGetAllNotifications()).unwrap();
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, []);

  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  return (
    <>
      {/* Header */}
      <div className="header">
        <div className="header-left">
          <Link to="/" className="logo">
            {/* <img src={logo_svg} alt="Logo" width={30} height={30} /> */}
          </Link>
          <Link to="/" className=" logo-small">
            {/* <img src={logo_small_svg} alt="Logo" width={30} height={30} /> */}
          </Link>
        </div>
        <Link
          className="mobile_btn"
          id="mobile_btn"
          to="#;"
          onClick={() => handlesidebar()}
        >
          <i className="fas fa-align-left" />
        </Link>
        <div className="header-split">
          {/* <div className="page-headers">
            <div className="search-bar">
              <span>
                <i className="fe fe-search">
                  <FeatherIcon icon={"search"} />
                </i>
              </span>
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              />
            </div>
          </div> */}
          <ul className="nav user-menu">
            {/* Notifications */}
            {/* <li className="nav-item" onClick={Reload}>
              <Link to="/" className="viewsite">
                <i className="fe fe-globe me-2">
                  <FeatherIcon icon={"globe"} />
                </i>
                View Site
              </Link>
            </li> */}
            {/* <li className="nav-item dropdown has-arrow dropdown-heads flag-nav">
              <Link
                className="nav-link"
                data-bs-toggle="dropdown"
                to="#;"
                role="button"
              >
                <img src={us1} alt="" height={20} />
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to="#;" className="dropdown-item">
                  <img src={us} className="me-2" alt="" height={16} /> English
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={fr} className="me-2" alt="" height={16} /> French
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={es} className="me-2" alt="" height={16} /> Spanish
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={de} className="me-2" alt="" height={16} /> German
                </Link>
              </div>
            </li> 
            <li className="nav-item  has-arrow dropdown-heads ">
              <Link to="#;" className="toggle-switch">
                <i className="fe fe-moon">
                  <FeatherIcon icon={"moon"} />
                </i>
              </Link>
            </li> */}
            <li className="nav-item dropdown has-arrow dropdown-heads ">
              <Link to="#;" data-bs-toggle="dropdown">
                <i className="fe fe-bell">
                  <FeatherIcon icon={"bell"} />
                </i>
              </Link>
              <div className="dropdown-menu notifications">
                <div className="topnav-dropdown-header">
                  <span className="notification-title">Notifications</span>
                  {/* <Link to="#;" className="clear-noti">
                    Clear All
                  </Link> */}
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    {notifications?.map((notification) => {
                      return (
                        <li
                          key={notification?.id}
                          className="notification-message"
                        >
                          <Link to="/bookings">
                            <div className="media d-flex">
                              {/* <span className="avatar avatar-sm flex-shrink-0">
                                <img
                                  className="avatar-img rounded-circle"
                                  alt=""
                                  src={provider_01}
                                />
                              </span> */}
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    {notification?.message}
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    {formatDate(notification?.createdAt)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                    {/* <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt=""
                              src={provider_02}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Matthew Garcia have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                13 Sep 2020 03:56 AM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt=""
                              src={provider_03}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Yolanda Potter have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                12 Sep 2020 09:25 PM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={provider_04}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Ricardo Flemings have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                11 Sep 2020 06:36 PM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={provider_05}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Maritza Wasson have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                10 Sep 2020 08:42 AM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={provider_06}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Marya Ruiz have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                9 Sep 2020 11:01 AM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/admin-notification">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={provider_07}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                Richard Hughes have been subscribed
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                8 Sep 2020 06:23 AM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="topnav-dropdown-footer">
                  <Link to="/admin-notification">View all Notifications</Link>
                </div> */}
              </div>
            </li>
            <li className="nav-item  has-arrow dropdown-heads ">
              <Link to="#" className="win-maximize maximize-icon">
                <FeatherIcon icon="maximize" />
              </Link>
            </li>

            {/* User Menu */}
            <li className="nav-item dropdown">
              <Link
                to="#;"
                className="user-link  nav-link"
                data-bs-toggle="dropdown"
              >
                <span className="user-img">
                  <img className="rounded-circle" src={adminLogo} alt="Admin" />
                  <span className="animate-circle" />
                </span>
                <span className="user-content">
                  <span className="user-name">{user?.name}</span>
                  {/* <span className="user-details">Demo User</span> */}
                </span>
              </Link>
              <div className="dropdown-menu menu-drop-user">
                <div className="profilemenu ">
                  <div className="user-detials">
                    <Link to="account">
                      <span className="profile-image">
                        <img
                          src={user_jpg}
                          alt="img"
                          className="profilesidebar"
                        />
                      </span>
                      <span className="profile-content">
                        <span>{user?.name}</span> | <span>{user?.email}</span>
                      </span>
                    </Link>
                  </div>
                  <div className="subscription-menu">
                    <ul>
                      {/* <li>
                        <Link to="/account-settings">Profile</Link>
                      </li> */}
                      <li>
                        <Link to="/settings">Settings</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="subscription-logout">
                    <Link onClick={() => dispatch(resetAuth())}>Log Out</Link>
                  </div>
                </div>
              </div>
            </li>
            {/* /User Menu */}
          </ul>
        </div>
      </div>
      {/* /Header */}
    </>
  );
};

export default AdminHeader;
