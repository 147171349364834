import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendors: {},
  inactive: {},
};

const VendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendors: (state, { payload }) => {
      state.vendors = payload.vendors || {};
      state.inactive = payload.inactive || {};
    },
    // setVendorProfile: (state, { payload }) => {
    //   if (!state.vendors["vendor_" + payload.id]) {
    //     state.vendors["vendor_" + payload.id] = {
    //       ...state.vendors["vendor_" + payload.id],
    //       ...payload,
    //     };

    //     if (state.inactive["vendor_" + payload.id]) {
    //       delete state.inactive["vendor_" + payload.id];
    //     }
    //   }
    // },
    setVendor: (state, { payload }) => {
      if (
        !state[payload.active ? "vendors" : "inactive"]["vendor_" + payload.id]
      ) {
        state[payload.active ? "vendors" : "inactive"] = {
          ["vendor_" + payload.id]: payload,
          ...state[payload.active ? "vendors" : "inactive"],
        };

        if (
          state[!payload.active ? "vendors" : "inactive"][
            "vendor_" + payload.id
          ]
        ) {
          delete state[!payload.active ? "vendors" : "inactive"][
            "vendor_" + payload.id
          ];
        }
      } else {
        state[payload.active ? "vendors" : "inactive"]["vendor_" + payload.id] =
          payload;
      }
    },
    removeVendor: (state, { payload }) => {
      delete state[payload.active ? "vendors" : "inactive"][
        "vendor_" + payload.id
      ];
    },
  },
});

export const { setVendors, setVendor, setVendorProfile, removeVendor } =
  VendorSlice.actions;
export default VendorSlice.reducer;

export const getVendorsCount = (state) => {
  return (
    Object.keys(state.vendor.vendors ?? {}).length +
    Object.keys(state.vendor.inactive ?? {}).length
  );
};
