import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { callApi } from "services/api";
import { store } from "store";
import { BLOG_BASE_URL_IMAGE } from "services/axios";
// import ImageRemoveEventCallbackPlugin from "ckeditor5-image-remove-event-callback-plugin"; // Add this
// ClassicEditor.builtinPlugins = [
//   // ...
//   ImageRemoveEventCallbackPlugin
//   // ...

// ]
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(function (resolve, reject) {
        try {
          loader.file
            .then(async (file) => {
              // console.log("upload", file);
              const token = store.getState().auth.token;

              const body = new FormData();
              body.append("file", file);

              const { success, data, message } = await callApi({
                token,
                path: "blogs/upload-image",
                method: "POST",
                body,
                isFormData: true,
              });
              if (success)
                // getBase64(file).then((url) => {
                resolve({
                  // default: `${HOST}/${response.data.filename}`,
                  default: `${BLOG_BASE_URL_IMAGE}${data}`,
                });
              // });
              else reject(message);
            })
            .catch((error) => {
              console.log("error", error);
              reject(error);
            });
        } catch (error) {
          console.log("error", error);
          throw error;
        }
      });
    },
    abort: (dd) => {
      console.log("abort");
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


const TextEditor = ({ value = "", onChange = () => {} }) => {
  return (
    <CKEditor
      plugins={['bulledList']}
      config={{
        extraPlugins: [uploadPlugin ],
        removePlugins: ['MediaEmbed', 'Table'],
      }}
      editor={ClassicEditor}
      data={value ?? ""}
      onReady={(editor) => {
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}

      // onBlur={(event, editor) => {
      //   console.log('Blur.', editor);
      // }}
      // onFocus={(event, editor) => {
      //   console.log('Focus.', editor);
      // }}
    />
  );
};

export default TextEditor;
