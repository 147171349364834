import { Form } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { asyncUpdatePassword } from "store/auth/authThunk";

const AdminSettings = () => {
  const [form] = Form.useForm();
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    dispatch(asyncUpdatePassword(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="login"
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-xxl-5 col-lg-10 col-md-8 col-sm-12 m-auto">
              <div className="content-page-header">
                <h5>Settings</h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Old Password</label>
                    <Form.Item
                      name="oldPassword"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Old password is required!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                      ]}
                    >
                      <span>
                        <input
                          autoComplete="new-password"
                          type={showPass ? "text" : "password"}
                          className="form-control pass-inputSave"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showPass ? "eye-off" : "eye"}
                            onClick={() => setShowPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>New Password</label>
                    <Form.Item
                      name="newPassword"
                      validateFirst
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Password is required!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                      ]}
                    >
                      <span>
                        <input
                          autoComplete="new-password"
                          type={showNewPass ? "text" : "password"}
                          className="form-control pass-inputSave"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showNewPass ? "eye-off" : "eye"}
                            onClick={() => setShowNewPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <Form.Item
                      name="confirmPassword"
                      dependencies={["newPassword"]}
                      validateFirst
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please confirm your password!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <span>
                        <input
                          autoComplete="new-password"
                          type={showConfirmPass ? "text" : "password"}
                          className="form-control pass-inputSave"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showConfirmPass ? "eye-off" : "eye"}
                            onClick={() => setShowConfirmPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="btn-path">
                    <button type="submit" className="btn btn-primary ">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default AdminSettings;
