import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Modal, Select, Upload } from "antd";
// import { upload } from "../../Admin-images";
import upload from "../../assets/images/about-banner.png";
import TextEditor from "components/editor/editor";
import styles from "./blogs.module.css";
import classNames from "classnames";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBlogById, selectBlogs } from "store/blogs/blogsSlice";
import { BASE_URL_IMAGE } from "services/axios";
import {
  asyncCreateBlog,
  asyncGetAllBlogs,
  asyncUpdateBlog,
} from "store/blogs/blogsThunk";

const { Dragger } = Upload;

const AdminAddBlog = () => {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const blogsArray = useSelector(selectBlogs);
  const { id: blogId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const blog = useSelector(selectBlogById(blogId));
  console.log(blog);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (blogId && !blogsArray.length) {
      dispatch(asyncGetAllBlogs())
        .unwrap()
        .then(({ data }) => {
          if (!data.length) history.back();
        });
    }
  }, [blogsArray, blogId, dispatch]);

  useEffect(() => {
    if (blogId) {
      form.setFieldsValue({
        title: blog?.title,
        shortText: blog?.shortText,
        textBox: blog?.textBox,
        thumbnailAlt: blog?.thumbnailAlt,
        bannerImageAlt: blog?.bannerImageAlt,
        metaTags: blog?.metaTags ? JSON.parse(blog.metaTags) : "",
        thumbnail: {
          uid: "-1",
          name: "thumbnail.png",
          active: "done",
          url: BASE_URL_IMAGE + blog?.thumbnail,
        },
        bannerImage: {
          uid: "-2",
          name: "bannerImage.png",
          active: "done",
          url: BASE_URL_IMAGE + blog?.bannerImage,
        },
      });
      setFileList([
        {
          uid: "-1",
          name: "thumbnail.png",
          active: "done",
          url: BASE_URL_IMAGE + blog?.thumbnail,
        },
      ]);
      setBannerList([
        {
          uid: "-2",
          name: "bannerImage.png",
          active: "done",
          url: BASE_URL_IMAGE + blog?.bannerImage,
        },
      ]);
      form.validateFields(["bannerImage", "thumbnail"]);
    }

    return () => {
      form.resetFields();
      setFileList([]);
      setBannerList([]);
    };
  }, [blogId]);

  const onFinish = async ({ bannerImage, metaTags, thumbnail, ...values }) => {
    let res = {};

    console.log("form", values);
    let formData = new FormData();
    Object.entries(values).map(([key, value]) => {
      formData.append(key, value);
    });

    if (fileList[0]?.originFileObj) {
      formData.append("thumbnail", thumbnail);
    }
    if (bannerList[0]?.originFileObj) {
      formData.append("bannerImage", bannerImage);
    }
    if (metaTags.length) {
      formData.append("metaTags", JSON.stringify(metaTags));
    }

    if (blogId) {
      res = await dispatch(
        asyncUpdateBlog({ id: blogId, body: formData })
      ).unwrap();
    } else {
      res = await dispatch(asyncCreateBlog(formData)).unwrap();
    }

    if (res.success) {
      return navigate("/blogs");
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = async ({ fileList: newFileList }) => {
    console.log(newFileList, "aaa");
    setFileList(newFileList);
    form.setFieldsValue({
      thumbnail: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["thumbnail"]);
  };
  const handleChangeBanner = async ({ fileList: newFileList }) => {
    if (!newFileList.length) return;
    const file = newFileList[0];
    setBannerList([{ ...file, url: await getBase64(file.originFileObj) }]);

    form.setFieldsValue({
      bannerImage: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["bannerImage"]);
  };
  console.log(bannerList, "bannerlist");

  return (
    <div className="page-wrapper">
      <div className="content">
        <Form
          form={form}
          name="blog"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            bannerImage: null,
            thumbnail: null,
            title: "",
            shortText: "",
            textBox: "",
            thumbnailAlt: "",
            bannerImageAlt: "",
            metaTags: [],
          }}
        >
          <div className="row">
            <div className="col-12 m-auto">
              <div className="content-page-header">
                {!blogId ? <h5>Add New Blog</h5> : <h5>Edit Blog</h5>}
              </div>
              <div className="row">
                <div>
                  <Form.Item
                    name="thumbnail"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: `Image is required!`,
                      },
                    ]}
                  >
                    <ImgCrop accept=".png,.jpeg,.jpg">
                      <Upload
                        multiple={false}
                        listType="picture-card"
                        customRequest={({ onSuccess }) => onSuccess("ok")}
                        fileList={fileList}
                        showUploadList={true}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        maxCount={1}
                      >
                        {fileList.length === 1 ? null : "Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-1">
                    <label>Blog Image</label>
                  </div>
                  <div className="form-uploads mb-4 banner-view">
                    <Form.Item
                      name="bannerImage"
                      validateFirst={true}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: `Image is required!`,
                      //   },
                      // ]}
                    >
                      <Dragger
                        className="mt-0"
                        onPreview={handlePreview}
                        onChange={handleChangeBanner}
                        maxCount={1}
                        fileList={fileList}
                        showUploadList={false}
                      >
                        <div
                          className={classNames(
                            "form-uploads-path",
                            styles.blogImage
                          )}
                        >
                          <img
                            src={bannerList.length ? bannerList[0].url : upload}
                            alt="img"
                          />
                          <div className={styles.blogBannerText}>
                            <div className="file-browse">
                              <h6>Drag &amp; drop image or </h6>

                              {/* <input type="file"  multiple={false} onChange={handleChangeBanner} /> */}
                              <Link to="#">
                                {" "}
                                {bannerList.length ? "Remove" : "Browse"}{" "}
                              </Link>
                            </div>
                            <h5>Supported formates: JPEG, PNG</h5>
                          </div>
                        </div>
                      </Dragger>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Title</label>
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Title is required!",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Hightlight Text</label>
                    <Form.Item
                      name="shortText"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Hightlight Text is required!",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter hightlight text"
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>Language</label>
                    <select className="form-control select">
                      <option>Select Language</option>
                      <option>English</option>
                      <option>German</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Category</label>
                    <select className="form-control select">
                      <option>Select Blog Category</option>
                      <option>Category 1</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Thumbnil Alt </label>
                    <Form.Item
                      name="thumbnailAlt"
                      // rules={[
                      //   {
                      //       required: true,
                      //       whitespace: true,
                      //       message: "Thumbnail Alt is required!",
                      //   },
                      // ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Thumbnil Alt"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Banner Image Alt</label>
                    <Form.Item
                      name="bannerImageAlt"
                      //   rules={[
                      //     {
                      //       required: true,
                      //       whitespace: true,
                      //       message: "Name is required!",
                      //     },
                      //   ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter bannerImageAlt"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Keywords<span>(Meta Tag)</span>
                    </label>
                    <Form.Item
                      name="metaTags"
                      // rules={[
                      //   {
                      //     required: true,
                      //     whitespace: true,
                      //     message: "Name is required!",
                      //   },
                      // ]}
                    >
                      <Select
                        className="form-control px-0 blog-Select"
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Meta Tags "
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Keywords"
                      /> */}
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label> Descriptions</label>
                    <div id="editor" />
                    <Form.Item
                      name="textBox"
                      // rules={[
                      //   {
                      //     required: true,
                      //     whitespace: true,
                      //     message: "Name is required!",
                      //   },
                      // ]}
                    >
                      <TextEditor />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="btn-path">
                    <Link to="/blogs" className="btn btn-cancel me-3">
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      {blogId ? "Update" : "Add"} Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </div>
  );
};
export default AdminAddBlog;
