import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { asyncAlertError, asyncAlertSuccess } from "../common/commonThunk";
import { setLoading } from "../common/commonSlice";
import {
  moveServiceToActive,
  setServices,
  setTrashedService,
} from "./serviceSlice";
import {
  moveSubServiceToActive,
  removeSubService,
} from "store/subService/subServiceSlice";

export const asyncGetAllServices = createAsyncThunk(
  "service/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/services",
        method: "GET",
        token,
      });

      let dataSource = {};
      console.log(res);
      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          let status = !curr.serviceTrashed ? "services" : "inactive";
          return {
            ...acc,
            [status]: { ...acc[status], ["service_" + curr.id]: curr },
          };
        }, {});

        dispatch(setServices({ ...dataSource }));
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncUpdateTrashServiceStatus = createAsyncThunk(
  "trash/service/update",
  async ({ id, condition }, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await callApi({
      path: `admin/update/trash/service/${id}`,
      method: "PUT",
      token,
      body: { condition },
      axiosSecure: false,
    });
    const { success, data, message } = res;
    if (success) {
      if (data.parentId) {
        dispatch(moveSubServiceToActive(data));
      } else {
        dispatch(moveServiceToActive(data));
      }
      dispatch(asyncAlertSuccess(message));
      return res;
    }
    dispatch(asyncAlertError(message));
    console.log("err", message);
    return res;
  }
);

// export const asyncGetAllTrashedServices = createAsyncThunk (
//   'trashed/service/list',
//   async(_,{dispatch,getState})=> {
//     const token = getState().auth.token;
//     try {
//       const token = getState().auth.token;

//       const res = await callApi({
//         path: "admin/trash/services",
//         method: "GET",
//         token,
//       });
//       console.log("res",res)
//       let dataSource = {};
//       if (res.success) {
//         dataSource = res.data.reduce((acc, curr) => {
//           let status = curr.serviceTrashed ? "trashed" : "active";
//           return {
//             ...acc,
//             [status]: { ...acc[status], ["service_" + curr.id]: curr },
//           };
//         }, {});

//         dispatch(setTrashedService(res.data));
//         return { success: true };
//       }
//       return { success: false };
//     } catch (error) {
//       return { success: false };
//     }
//   }
// )

export const asyncGetAllTrashedServices = createAsyncThunk(
  "trashed/service/list",
  async (_, { dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      const res = await callApi({
        path: "admin/trash/services",
        method: "GET",
        token,
      });
      console.log("response", res);
      if (res.success) {
        const dataSource = res.data.reduce((acc, curr) => {
          const status = curr.serviceTrashed ? "trashed" : "active";
          return {
            ...acc,
            [status]: { ...acc[status], ["service_" + curr.id]: curr },
          };
        }, {});

        dispatch(setTrashedService(dataSource)); // Dispatch the action with trashed services data
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncCreateService = createAsyncThunk(
  "service/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/services`,
        method: "POST",
        body,
        token,
        isFormData: true,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Service Successfully Created!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateService = createAsyncThunk(
  "service/update",
  async ({ serviceId, body }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/services/${serviceId}`,
        method: "PUT",
        body,
        token,
        isFormData: true,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Service Successfully Updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncMarkServiceFeatured = createAsyncThunk(
  "service/featured",
  async ({ serviceId, isFeatured }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/featured-services/${serviceId}`,
        method: "PUT",
        body: { isFeatured },
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// export const asyncBanService = createAsyncThunk(
//   "service/ban",
//   async ({ id }, { dispatch, getState }) => {
//     try {
//       dispatch(setLoading(true));

//       const { token } = getState().auth;

//       const res = await callApi({
//         path: `admin/ban/${id}`,
//         method: "PUT",
//         token,
//       });

//       if (res.success) {
//         return { success: true };
//       }

//       dispatch(asyncAlertError(res.message));
//       return { success: false };
//     } catch (error) {
//       dispatch(asyncAlertError(error.message));
//       return { success: false };
//     } finally {
//       dispatch(setLoading(false));
//     }
//   }
// );

export const asyncDeleteService = createAsyncThunk(
  "service/delete",
  async (serviceId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `admin/services/${serviceId}`,
        method: "DELETE",
        token,
      });

      if (res?.success) {
        dispatch(
          // asyncAlertSuccess(
          //   `${
          //     res.data.parentId ? "SubService" : "Service"
          //   } Successfully Deleted!`
          // )
          asyncAlertSuccess(res.message)
        );
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
