import React from "react";
import Select from "react-select";
import styles from "./style.module.css";
const SelectBox = (props) => {
  return (
    <Select
      {...props}
      classNames={{
        control: () => styles.control,
        placeholder: () => styles.placeholder,
      }}
      styles={{
        control: (sss) => ({ ...sss, borderStyle: "solid !important", height:"auto" }),
      }}
    />
  );
};
export default SelectBox;
