import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import common from "./common/commonSlice";
import service from "./service/serviceSlice";
import subService from "./subService/subServiceSlice";
import vendor from "./vendor/vendorSlice";
import booking from "./booking/bookingSlice";
import customer from "./customer/customerSlice";
import payment from "./payment/paymentSlice";
import blogs from "./blogs/blogsSlice";
import notification from "./notification/notificationSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const allreducers = combineReducers({
  auth,
  common,
  service,
  subService,
  vendor,
  booking,
  customer,
  payment,
  notification,
  blogs,
});

const persistConfig = {
  key: "relocates-admin",
  version: 1,
  storage,
  whitelist: [
    "auth",
    "service",
    "subService",
    "vendor",
    "customer",
    "booking",
    "notification",
    "blogs",
  ],
};

const rootReducer = (state, action) => {
  if (action.type === "auth/resetAuth") {
    state = {};
  }
  return allreducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
