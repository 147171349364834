import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";

import logo_small_svg from "assets/images/logo/FooterLogoR.png";
import logo_svg from "assets/images/logo/relocatesLogo.png";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation } from "react-router-dom";
import { resetAuth } from "store/auth/authSlice";
import { useDispatch } from "react-redux";
import "./mainSidebar.css";

const MainSidebar = () => {
  const [isSideMenu, setSideMenu] = useState("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const handleSidebar = (e) => {
    // console.log(e.target.checked,"e.target.checked");
    if (e.target.checked == true) {
      document.body.classList.add("mini-sidebar");
    } else if (e.target.checked == false) {
      document.body.classList.remove("mini-sidebar");
    }
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const handlesidebar = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.toggle("menu-opened");
    var rootss = document.getElementById("session");
    rootss.classList.toggle("slide-nav");
  };

  return (
    <>
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseOver={expandMenuOpen}
        onMouseLeave={expandMenu}
      >
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-header d-flex align-items-center">
            <div className="sidebar-logo">
              <Link to="/">
                <img src={logo_svg} className="img-fluid logo" alt="" />
              </Link>
              <Link to="/">
                <img
                  src={logo_small_svg}
                  className="img-fluid logo-small"
                  alt=""
                />
              </Link>
            </div>

            <Link id="mobile_btn" to="#;" onClick={() => handlesidebar()}>
              <i className="fas fa-align-left" />
            </Link>
            {/* <div className="siderbar-toggle">
              <label className="switch" id="toggle_btn">
                <input type="checkbox" onChange={(e) => handleSidebar(e)} />
                <span className="slider round" />
              </label>
            </div> */}
          </div>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className="menu-title m-0">
                  <h6>Home</h6>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/" ? "active" : ""}`}
                    to="/"
                  >
                    <i className="fe fe-grid">
                      <FeatherIcon icon={"grid"} />
                    </i>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Services</h6>
                </li>

                <li className="submenu">
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                    className={`${isSideMenu === "/services" ? "subdrop " : ""}
                     ${pathname.includes("/services") ? "active" : ""}
                    `}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/services" ? "" : "/services"
                      )
                    }
                  >
                    <i className="fe fe-briefcase">
                      <FeatherIcon icon={"briefcase"} />
                    </i>
                    <span>Services</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>
                    </span>
                  </a>
                  <ul
                    className={`${
                      isSideMenu === "/services" ? "d-block " : "none"
                    }`}
                  >
                    <li>
                      <Link
                        className={` ${
                          pathname === "/services/add" ? "active" : ""
                        }`}
                        to="/services/add"
                      >
                        Add Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/sub_services/add" ? "active" : ""
                        }`}
                        to="/sub_services/add"
                      >
                        Add Sub Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/services" ? "active" : ""
                        }`}
                        to="/services"
                      >
                        Services List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/sub_services" ? "active" : ""
                        }`}
                        to="/sub_services"
                      >
                        Sub Services List
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-title">
                  <h6>User Management</h6>
                </li>
                <li className="submenu">
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                    className={`${isSideMenu === "/vendors" ? "subdrop " : ""}
                     ${pathname.includes("/vendors") ? "active" : ""}
                    `}
                    onClick={() =>
                      toggleSidebar(isSideMenu === "/vendors" ? "" : "/vendors")
                    }
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>
                    <span>Vendors</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>{" "}
                    </span>
                  </a>
                  <ul
                    className={`${
                      isSideMenu === "/vendors" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link
                        className={` ${
                          pathname === "/vendors/add" ? "active" : ""
                        }`}
                        to="/vendors/add"
                      >
                        Add Vendor
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/vendors" ? "active" : ""
                        }`}
                        to="/vendors"
                      >
                        Vendors
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className={` ${pathname === "/customers" ? "active" : ""}`}
                    to="/customers"
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>{" "}
                    <span>Customers</span>
                  </Link>
                </li>

                <li>
                  <Link
                    className={` ${pathname === "/bookings" ? "active" : ""}`}
                    to="/bookings"
                  >
                    <i className="fe fe-smartphone">
                      <FeatherIcon icon={"smartphone"} />
                    </i>
                    <span>Bookings</span>
                  </Link>
                </li>

                <li>
                  <Link
                    className={` ${pathname === "/earnings" ? "active" : ""}`}
                    to="/earnings"
                  >
                    <i className="fe fe-dollar-sign">
                      <FeatherIcon icon={"dollar-sign"} />
                    </i>
                    <span>Earnings</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>BLOGS</h6>
                </li>
                <li className="submenu">
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                    className={`${isSideMenu === "/blogs" ? "subdrop " : ""}
                     ${pathname.includes("/blogs") ? "active" : ""}
                    `}
                    onClick={() =>
                      toggleSidebar(isSideMenu === "/blogs" ? "" : "/blogs")
                    }
                  
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>
                    <span>Blogs</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>{" "}
                    </span>
                  </a>
                  <ul
                    className={`${
                      isSideMenu === "/blogs" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link
                        className={` ${
                          pathname === "/blogs/add" ? "active" : ""
                        }`}
                        to="/blogs/add"
                      >
                        Add Blogs
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className={` ${
                          pathname === "/blogs/edit" ? "active" : ""
                        }`}
                        to="/blogs/edit"
                      >
                        Edit Blog
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className={` ${
                          pathname === "/blogs" ? "active" : ""
                        }`}
                        to="/blogs"
                      >
                        Blogs List
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li className="menu-title">
                  <h6>Booking</h6>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`${isSideMenu === "/bookings" ? "subdrop " : ""}
                     ${pathname.includes("/bookings") ? "active" : ""}
                    `}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/bookings" ? "" : "/bookings"
                      )
                    }
                  >
                    <i className="fe fe-smartphone">
                      <FeatherIcon icon={"smartphone"} />
                    </i>
                    <span> Bookings</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>{" "}
                    </span>
                  </Link>
                  <ul
                    className={`${
                      isSideMenu === "/bookings" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link
                        className={` ${
                          pathname === "/bookings" ? "active" : ""
                        }`}
                        to="/bookings"
                      >
                        All
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/bookings/pending" ? "active" : ""
                        }`}
                        to="/bookings/pending"
                      >
                        Pending
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/bookings/inprogress" ? "active" : ""
                        }`}
                        to="/bookings/inprogress"
                      >
                        Inprogress
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/bookings/completed" ? "active" : ""
                        }`}
                        to="/bookings/completed"
                      >
                        Completed
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={` ${
                          pathname === "/bookings/cancelled" ? "active" : ""
                        }`}
                        to="/bookings/cancelled"
                      >
                        Cancelled
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li className="menu-title">
                  <h6>Finance &amp; Accounts</h6>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/banktransferlist" ? "active" : ""
                    }`}
                    to="/banktransferlist"
                  >
                    <i className="fe fe-file">
                      <FeatherIcon icon={"file"} />
                    </i>
                    <span>Bank Transfer</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/wallet" ? "active" : ""}`}
                    to="/wallet"
                  >
                    <i className="fe fe-credit-card">
                      <FeatherIcon icon={"credit-card"} />
                    </i>
                    <span>Wallet</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/refund-request" ? "active" : ""
                    }`}
                    to="/refund-request"
                  >
                    <i className="fe fe-git-pull-request">
                      <FeatherIcon icon={"git-pull-request"} />
                    </i>{" "}
                    <span>Refund Request</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/cash-on-delivery" ? "active" : ""
                    }`}
                    to="/cash-on-delivery"
                  >
                    <i className="fe fe-dollar-sign">
                      <FeatherIcon icon={"dollar-sign"} />
                    </i>{" "}
                    <span>Cash on Delivery</span>
                  </Link>
                </li>

                <li className="submenu">
                  <Link
                    to="#"
                    className={`${
                      isSideMenu === "/payout-request" ? "subdrop" : ""
                    }`}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/payout-request"
                          ? ""
                          : "/payout-request"
                      )
                    }
                  >
                    <i className="fe fe-credit-card">
                      <FeatherIcon icon={"credit-card"} />
                    </i>
                    <span>Payouts</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>
                    </span>
                  </Link>
                  <ul
                    className={`${
                      isSideMenu === "/payout-request" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link to="/payout-request">Payout Requests</Link>
                    </li>
                    <li>
                      <Link to="/payout-settings">Payout Settings</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className={` ${
                      pathname === "/sales-transactions" ? "active" : ""
                    }`}
                    to="/sales-transactions"
                  >
                    <i className="fe fe-bar-chart">
                      <FeatherIcon icon={"bar-chart"} />
                    </i>{" "}
                    <span>Sales Transactions</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/tax-rates" ? "active" : ""}`}
                    to="/tax-rates"
                  >
                    <i className="fe fe-file-text">
                      <FeatherIcon icon={"file-text"} />
                    </i>
                    <span>Tax Rates</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Others</h6>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/chat" ? "active" : ""}`}
                    to="/chat"
                  >
                    <i className="fe fe-message-square">
                      <FeatherIcon icon={"message-square"} />
                    </i>{" "}
                    <span>Chat</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Content</h6>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`${isSideMenu === "/add-page" ? "subdrop" : ""}`}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/add-page" ? "" : "/add-page"
                      )
                    }
                  >
                    <i className="fe fe-file">
                      <FeatherIcon icon={"file"} />
                    </i>
                    <span>Pages</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>
                    </span>
                  </Link>
                  <ul
                    className={`${
                      isSideMenu === "/add-page" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link to="/add-page">Add Page</Link>
                    </li>
                    <li>
                      <Link to="/pages-list">Pages</Link>
                    </li>
                    <li>
                      <Link to="/page-list">Pages List</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`${isSideMenu === "/all-blog" ? "subdrop" : ""}`}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/all-blog" ? "" : "/all-blog"
                      )
                    }
                  >
                    <i className="fe fe-file-text">
                      <FeatherIcon icon={"file-text"} />
                    </i>
                    <span>Blog</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>
                    </span>
                  </Link>
                  <ul
                    className={`${
                      isSideMenu === "/all-blog" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link to="/all-blog">All Blog</Link>
                    </li>
                    <li>
                      <Link to="/add-blog">Add Blog</Link>
                    </li>
                    <li>
                      <Link to="/blogs-categories">Categories</Link>
                    </li>
                    <li>
                      <Link to="/blogs-comments">Blog Comments</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`${
                      isSideMenu === "/countries" ? "subdrop" : ""
                    }`}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "/countries" ? "" : "/countries"
                      )
                    }
                  >
                    <i className="fe fe-map-pin">
                      <FeatherIcon icon={"map-pin"} />
                    </i>
                    <span>Location</span>
                    <span className="menu-arrow">
                      <i className="fe fe-chevron-right">
                        <FeatherIcon icon={"chevron-right"} />
                      </i>{" "}
                    </span>
                  </Link>
                  <ul
                    className={`${
                      isSideMenu === "/countries" ? "d-block" : "none"
                    }`}
                  >
                    <li>
                      <Link to="/countries">Countries</Link>
                    </li>
                    <li>
                      <Link to="/states">States</Link>
                    </li>
                    <li>
                      <Link to="/cities">Cities</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/testimonials" ? "active" : ""
                    }`}
                    to="/testimonials"
                  >
                    <i className="fe fe-star">
                      <FeatherIcon icon={"star"} />
                    </i>{" "}
                    <span>Testimonials</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/faq" ? "active" : ""}`}
                    to="/faq"
                  >
                    <i className="fe fe-help-circle">
                      <FeatherIcon icon={"help-circle"} />
                    </i>
                    <span>FAQ</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Membership</h6>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/membership" ? "active" : ""}`}
                    to="/membership"
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>{" "}
                    <span>Membership</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/membership-addons" ? "active" : ""
                    }`}
                    to="/membership-addons"
                  >
                    <i className="fe fe-user-plus">
                      <FeatherIcon icon={"user-plus"} />
                    </i>{" "}
                    <span>Membership Addons</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Reports</h6>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/earnings" ? "active" : ""}`}
                    to="/earnings"
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>
                    <span>Admin Earnings</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/provider-earnings" ? "active" : ""
                    }`}
                    to="/provider-earnings"
                  >
                    <i className="fe fe-dollar-sign">
                      <FeatherIcon icon={"dollar-sign"} />
                    </i>
                    <span>Provider Earnings</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/membership-transaction" ? "active" : ""
                    }`}
                    to="/membership-transaction"
                  >
                    <i className="fe fe-tv">
                      <FeatherIcon icon={"tv"} />
                    </i>
                    <span>Membership Transaction</span>
                  </Link>
                </li>

                <li className="menu-title">
                  <h6>Marketing</h6>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/marketing-coupons" ? "active" : ""
                    }`}
                    to="/marketing-coupons"
                  >
                    <i className="fe fe-bookmark">
                      <FeatherIcon icon={"bookmark"} />
                    </i>{" "}
                    <span>Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/marketing-service" ? "active" : ""
                    }`}
                    to="/marketing-service"
                  >
                    <i className="fe fe-briefcase">
                      <FeatherIcon icon={"briefcase"} />
                    </i>{" "}
                    <span>Service Offers</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/marketing-featured" ? "active" : ""
                    }`}
                    to="/marketing-featured"
                  >
                    <i className="fe fe-briefcase">
                      <FeatherIcon icon={"briefcase"} />
                    </i>{" "}
                    <span>Featured Services</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/marketing-newsletter" ? "active" : ""
                    }`}
                    to="/marketing-newsletter"
                  >
                    <i className="fe fe-mail">
                      <FeatherIcon icon={"mail"} />
                    </i>{" "}
                    <span>Email Newsletter</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Management</h6>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/cachesystem" ? "active" : ""
                    }`}
                    to="/cachesystem"
                  >
                    <i className="fe fe-user">
                      <FeatherIcon icon={"user"} />
                    </i>
                    <span>Cache System</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/email-templates" ? "active" : ""
                    }`}
                    to="/email-templates"
                  >
                    <i className="fe fe-mail">
                      <FeatherIcon icon={"mail"} />
                    </i>{" "}
                    <span>Email Templates</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/sms-templates" ? "active" : ""
                    }`}
                    to="/sms-templates"
                  >
                    <i className="fe fe-message-square">
                      <FeatherIcon icon={"message-square"} />
                    </i>{" "}
                    <span>SMS Templates</span>
                  </Link>
                </li>
                <li className="menu-title">
                  <h6>Support</h6>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/contact-messages" ? "active" : ""
                    }`}
                    to="/contact-messages"
                  >
                    <i className="fe fe-message-square">
                      <FeatherIcon icon={"message-square"} />
                    </i>{" "}
                    <span>Contact Messages</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/abuse-reports" ? "active" : ""
                    }`}
                    to="/abuse-reports"
                  >
                    <i className="fe fe-file-text">
                      <FeatherIcon icon={"file-text"} />
                    </i>{" "}
                    <span>Abuse Reports</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      pathname === "/announcements" ? "active" : ""
                    }`}
                    to="/announcements"
                  >
                    <i className="fe fe-volume-2">
                      <FeatherIcon icon={"volume-2"} />
                    </i>{" "}
                    <span>Announcements</span>
                  </Link>
                </li> */}
                <li className="menu-title">
                  <h6>Settings</h6>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "/settings" ? "active" : ""}`}
                    to="/settings"
                  >
                    <i className="fe fe-settings">
                      <FeatherIcon icon={"settings"} />
                    </i>{" "}
                    <span>Settings</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${pathname === "//signin" ? "active" : ""}`}
                    // to="/signin"
                    onClick={() => dispatch(resetAuth())}
                  >
                    <i className="fe fe-log-out">
                      <FeatherIcon icon={"log-out"} />
                    </i>{" "}
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default MainSidebar;
