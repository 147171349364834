import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { removeBlog, setBlog, setBlogs } from "./blogsSlice";
import { setLoading } from "store/common/commonSlice";
import { asyncAlertError, asyncAlertSuccess } from "store/common/commonThunk";
import { message } from "antd";

export const asyncGetAllBlogs = createAsyncThunk(
  "blogs/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "blogs",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setBlogs(res.data));
        return res;
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncCreateBlog = createAsyncThunk(
  "blog/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "blogs",
        method: "POST",
        body,
        token,
        isFormData: true,
      });

      if (res.success) {
        dispatch(setBlog(res.data));
        return res;
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateBlog = createAsyncThunk(
  "blogs/:id/update",
  async ({ id, body }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `blogs/${id}`,
        method: "PUT",
        token,
        body,
        isFormData: true,
      });

      if (res.success) {
        dispatch(setBlog(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncDeleteBlog = createAsyncThunk(
  "blogs/:id/delete",
  async (blogId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;
      const res = await callApi({
        path: `blogs/${blogId}`,
        method: "DELETE",
        token,
      });

      if (res?.success) {
        dispatch(removeBlog(blogId));
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
