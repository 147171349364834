import { createAsyncThunk } from "@reduxjs/toolkit";
import { asyncAlertError, asyncAlertSuccess } from "store/common/commonThunk";
import { setLoading } from "store/common/commonSlice";
import { resetAuth, setAuth } from "./authSlice";
import { callApi } from "services/api";

export const asyncUpdateToken = createAsyncThunk(
  "accessToken",
  async (_, { dispatch, getState }) => {
    try {
      const res = await callApi({
        path: "auth/accesstoken",
        method: "PUT",
        axiosSecure: false,
        token: getState().auth.refreshToken,
      });

      if (res.success) {
        dispatch(
          setAuth({
            token: res.accessToken,
          })
        );
        return { success: true, token: res.accessToken };
      }

      return res;
    } catch (error) {
      return error;
    }
  }
);

export const asyncRegisterUser = createAsyncThunk(
  "register",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      const res = await callApi({
        path: "auth/register",
        method: "POST",
        body,
      });

      if (res.success) {
        dispatch(
          setAuth({
            user: res.data,
            token: res.token,
            refreshToken: res.refreshToken,
          })
        );
        return { success: true };
      }
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncLoginUser = createAsyncThunk(
  "login",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      const res = await callApi({
        path: "auth/login",
        method: "POST",
        body,
        axiosSecure: false,
      });

      if (res.data?.role && res.data?.role !== "1") {
        dispatch(asyncAlertError("You are not allowed to login here"));
        return { success: false };
      }

      if (res.success) {
        dispatch(
          setAuth({
            user: res.data,
            token: res.token,
            refreshToken: res.refreshToken,
          })
        );
        dispatch(asyncAlertSuccess("Logged In"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdatePassword = createAsyncThunk(
  "update/password",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "auth/change-password",
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        dispatch(resetAuth());
        dispatch(
          asyncAlertSuccess(
            "Password Updated Successfully, Please Login Again!"
          )
        );
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncLogOutUser = createAsyncThunk(
  "logout",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "auth/logout",
        method: "PUT",
        token,
      });

      if (res.success) {
        dispatch(resetAuth());
        dispatch(asyncAlertSuccess("Logged Out"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncForgotPassword = createAsyncThunk(
  "forgot/password",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await callApi({
        path: "admin/forgot/password",
        method: "POST",
        body,
        axiosSecure: false,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError("Something went wrong, Please try again"));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncResetPassword = createAsyncThunk(
  "reset/password",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await callApi({
        path: "admin/reset/password",
        method: "POST",
        body,
        axiosSecure: false,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const asyncVerifyToken = createAsyncThunk(
  "verifyToken",
  async (body, { dispatch }) => {
    const res = await callApi({
      path: "users/validate-resetLink",
      method: "POST",
      axiosSecure: false,
      body,
    });
    if(res.success){
      dispatch(asyncAlertSuccess(res.message));
      return res;
    }else{
      dispatch(asyncAlertError(res.message));
      return res;
    }
  }
);
